import React from "react";
import { getYear, getDay, getMonth, taxPagination } from "../utils/util";
import { Link } from "react-router-dom";
import { categories } from "../utils/string";

const BlogPageBody = ({
  param,
  page,
  total,
  perpage,
  query,
  category,
  category_query,
}) => {
  const list = [];
  var taxcat;
  var pencarian = "";
  if (query) {
    pencarian = (
      <h2>
        Search Results for: <strong>"{query}"</strong>
      </h2>
    );
  }
  console.log(category);
  // for(var i=0; i<=category.length;i++){
  //     taxcat = category[i];
  //     console.log(taxcat.map);

  //     if (taxcat.ID == 0){
  //         list.push( <Link to={"/tax/page/1?cat="+taxcat.ID} key={taxcat.ID}><li>{taxcat.NAME}</li></Link>)
  //     }
  // }
  {
    category &&
      category.map((d) => {
        if (d.PARENT_ID == 0) {
          list.push(
            <Link to={"/tax/page/1?cat=" + d.ID} key={d.ID}>
              <li>{d.NAME}</li>
              {category &&
                category.map((c) => {
                  if (c.PARENT_ID == d.ID) {
                    return (
                      <Link to={"/tax/page/1?cat=" + c.ID} key={c.ID}>
                        <ul>
                          <li>{c.NAME}</li>
                          {category &&
                            category.map((a) => {
                              if (a.PARENT_ID == c.ID) {
                                return (
                                  <Link
                                    to={"/tax/page/1?cat=" + a.ID}
                                    key={a.ID}
                                  >
                                    <ul>
                                      <li>{a.NAME}</li>
                                    </ul>
                                  </Link>
                                );
                              }
                            })}
                        </ul>
                      </Link>
                    );
                  }
                })}
            </Link>
          );
        }
      });
  }
  return (
    <div className="container">
      {pencarian}
      <div className="row pt-1 pb-5 mb-3">
        <div className="col-lg-9">
          {param &&
            param.map((d) => (
              <div key={d.ID}>
                <div className="row mt-2 mb-2">
                  <article className="blog-post col">
                    <div className="row">
                      <div className="col-sm-8 col-lg-5">
                        <div className="blog-post-image-wrapper">
                          <a href={"/tax/" + d.SLUG + "/#detailTax"}>
                            <img
                              src={d.FULL_PATH_IMAGE}
                              alt=""
                              className="img-fluid mb-4"
                            />
                          </a>
                          {/* <Link to={"/tax/" + d.SLUG}>
                            <img
                              src={d.FULL_PATH_IMAGE}
                              alt=""
                              className="img-fluid mb-4"
                            />
                          </Link> */}
                          {/* <span
                            className="blog-post-date bg-color-primary text-color-light font-weight-bold"
                            style={{ backgroundColor: "#f57f22 !important" }}
                          >
                            {getDay(d.DATE_ADDED)}
                            <span className="month-year font-weight-light">
                              {getMonth(d.DATE_ADDED)}-{getYear(d.DATE_ADDED)}
                            </span>
                          </span> */}
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-7">
                        <h2> {d.TITLE}</h2>
                        <p>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: d.DESC_NEWS.replace(
                                /<[^>]+>/g,
                                ""
                              ).substring(0, 190),
                            }}
                          ></span>
                          ...
                        </p>
                        <span className="info posted-by">
                          Tags :
                          <span className="post-author font-weight-semibold text-color-dark">
                            {d.TAG}
                          </span>
                        </span>
                        <hr className="solid" />
                        <a href={"/tax/" + d.SLUG + "/#detailTax"}>
                          <button
                            className="btn btn-outline custom-border-width btn-primary custom-border-radius font-weight-semibold text-uppercase"
                            title="Read More"
                          >
                            Read More
                          </button>
                        </a>
                        {/* <Link to={"/tax/" + d.SLUG}>
                          <button
                            className="btn btn-outline custom-border-width btn-primary custom-border-radius font-weight-semibold text-uppercase"
                            title="Read More"
                          >
                            Read More
                          </button>
                        </Link> */}
                      </div>
                    </div>
                  </article>
                </div>
                <hr className="solid tall mt-5" />
              </div>
            ))}
          <div className="text-center">
            {taxPagination(total, page, perpage, query, category_query)}
          </div>
        </div>
        <div className="col-lg-3">
          <h4 className="font-weight-bold text-color-dark mt-3">
            - {categories[window.lang]}
          </h4>
          <ul>{list}</ul>
        </div>
      </div>
    </div>
  );
};

export default BlogPageBody;
