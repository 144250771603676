import React from "react";
import Carousel from "react-elastic-carousel";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import axios from "axios";
import { API_PERSON_LEADER, API_MAIN } from "../utils/constants";
import { read_more } from "../utils/string";

export class Leader extends React.Component {
  constructor(props) {
    super(props);
    this.breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
      { width: 850, itemsToShow: 4 },
      { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
      { width: 1450, itemsToShow: 5 },
      { width: 1750, itemsToShow: 6 },
    ];
    this.state = {
      leader: [],
      main: [],
    };
  }

  componentDidMount() {
    this.getPersonLeader();
    this.getMain();
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }
  getPersonLeader() {
    const headers = {};
    axios
      .get(API_PERSON_LEADER, { headers: headers })
      .then((res) => {
        const leader = res.data.person;
        this.setState({ leader });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { leader, main } = this.state;
    return (
      <section className="custom-section-padding">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>{main.leaders}</h2>
            </div>
            {/* <div className="col-right">
              <span onClick={() => this.carousel.slidePrev()}>
                <FaAngleLeft size={40} color="#f57f22" />
              </span>
              <span onClick={() => this.carousel.slideNext()}>
                <FaAngleRight size={40} color="#f57f22" />
              </span>
            </div> */}
          </div>
          <div className="row">
            <div className="col pl-0">
              <div
                className="row team-list sort-destination"
                data-sort-id="team"
              >
                {/* <Carousel
                  ref={(ref) => (this.carousel = ref)}
                  showArrows={false}
                  itemsToShow={2}
                  breakPoints={this.breakPoints}
                  onResize={(currentBreakPoint) =>
                     console.log(currentBreakPoint)
                  }
                > */}
                  {/* {leader &&
                    leader.map((d) => (
                      <div key={d.ID}> */}
                        {/* <div className="team-item mb-3 p-0 pb-2">
                                            <a  href={"/person/"+d.SLUG} className="text-decoration-none">
                                            <span className="image-wrapper">
                                                <img src={d.FULL_PATH_IMAGE} alt="" className="img-fluid" />
                                            </span>
                                            </a>
                                            <div className="team-infos">
                                                <div className="share">
                                                    <i className="fas fa-share-alt" />
                                                    <div className="share-icons bg-color-light">
                                                    <a href="/#" className="text-decoration-none" title="Share on Facebook"><i className="fab fa-facebook-f" /></a>
                                                    <a href="/#" className="text-decoration-none" title="Share on Instagram"><i className="fab fa-instagram" /></a>
                                                    <a href="/#" className="text-decoration-none" title="Share on Linkedin"><i className="fab fa-linkedin-in" /></a>
                                                    </div>
                                                </div>
                                                <a href={"/person/"+d.SLUG} className="text-decoration-none">
                                                    <span className="team-member-name text-color-dark font-weight-semibold text-4">{d.NAME}</span>
                                                    <span className="team-member-desc font-weight-light">{d.POSITION}</span>
                                                </a>
                                            </div>
                                        </div> */}
                        {/* <div class="col-md-12 text-center">
                              <img src={d.FULL_PATH_IMAGE}  alt="" class="img-fluid" width="200xp"/>
                              <h1 class="mt-0 mb-0">{d.NAME}</h1>
                              <p class="lead ml-4 pt-1">{d.POSITION}</p>
                              <div class="col">
                                  <p align="justify">
                                      {<div dangerouslySetInnerHTML={ { __html: d.DESC_PERSON } }></div>}
                                  </p>
                              </div> */}
                        {/* <div class="team-item">
                            <div class="team-infos">
                                <div class="share">
                                    <i class="fas fa-share-alt"></i>
                                    <div class="share-icons bg-color-light">
                                        <a href="/#" class="text-decoration-none" title="Share on Facebook"><i class="fab fa-facebook-f"></i></a>
                                        <a href="/#" class="text-decoration-none" title="Share on Instagram"><i class="fab fa-instagram"></i></a>
                                        <a href="/#" class="text-decoration-none" title="Share on Linkedin"><i class="fab fa-linkedin-in"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* </div>                                        */}
                        {/* <div class="container">
                          <div class="row pt-1 pb-4 mb-3">
                            <div class="col">
                              <div class="row mb-4">
                                <div class="col-md-6 text-center">
                                  <div class="team-item pl-0">
                                    <span class="image-wrapper">
                                      <img
                                        src={d.FULL_PATH_IMAGE}
                                        alt=""
                                        class="img-fluid"
                                      />
                                    </span>
                                    <div class="team-infos"> */}
                                      {/* <div class="share">
                                            <i class="fas fa-share-alt"></i>
                                            <div class="share-icons bg-color-light">
                                                <a href="/#" class="text-decoration-none" title="Share on Facebook"><i class="fab fa-facebook-f"></i></a>
                                                <a href="/#" class="text-decoration-none" title="Share on Instagram"><i class="fab fa-instagram"></i></a>
                                                <a href="/#" class="text-decoration-none" title="Share on Linkedin"><i class="fab fa-linkedin-in"></i></a>
                                            </div>
                                        </div> */}
                                    {/* </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <h4 class="mt-0 mb-0">{d.NAME}</h4>
                                  <p class="lead pt-1">{d.POSITION}</p>
                                  <p align="left">
                                    {
                                      <div
                                        className="line-camp"
                                        dangerouslySetInnerHTML={{
                                          __html: d.SIMPLE_DESC_PERSON,
                                        }}
                                      ></div>
                                    }
                                  </p>
                                  <a
                                    href={"/person/" + d.SLUG}
                                    className="btn btn-outline custom-border-width btn-primary font-weight-semibold text-uppercase"
                                    title="Read More"
                                  >
                                    {main.read_more}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Carousel> */}
                {leader &&
                    leader.map((d) => (
                      <div class="container col-md-6">
                          <div class="row pt-1 pb-4 mb-3">
                            <div class="col">
                              <div class="row mb-4">
                                <div class="col-md-6 text-center">
                                  <div class="team-item pl-0">
                                    <span class="image-wrapper">
                                      <img
                                        src={d.FULL_PATH_IMAGE}
                                        alt=""
                                        class="img-fluid"
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <h4 class="mt-0 mb-0">{d.NAME}</h4>
                                  <p class="lead pt-1">{d.POSITION}</p>
                                  <p align="left">
                                    {
                                      <div
                                        className="line-camp"
                                        dangerouslySetInnerHTML={{
                                          __html: d.SIMPLE_DESC_PERSON,
                                        }}
                                      ></div>
                                    }
                                  </p>
                                  <a
                                    href={"/person/" + d.SLUG}
                                    className="btn btn-outline custom-border-width btn-primary font-weight-semibold text-uppercase"
                                    title="Read More"
                                  >
                                    {main.read_more}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-left">
              {/* <Link to="/about" className="btn btn-outline custom-border-width btn-primary custom-border-radius font-weight-semibold text-uppercase mb-3">READ MORE</Link> */}
              <a
                href="/about"
                className="c-button btn btn-primary btn-modern font-weight-bold text-2 py-3 btn-px-4 appear-animation"
                data-appear-animation="fadeInUpShorter"
                data-appear-animation-delay="1500"
              >
                {main.read_more}
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Leader;
