import React from "react"

const PageHeader = ({param}) => {

    return (
        <section className="page-header page-header-modern bg-color-quaternary page-header-md custom-page-header">
            <div className="container" style={{marginTop:'30px'}}>
                <div className="row mt-3">
                    <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
                        <h1>{param.title}</h1>
                        <span className="d-block text-4">{param.subtitle}</span>
                    </div>
                    <div className="col-md-4 order-1 order-md-2 align-self-center">
                        <ul className="breadcrumb d-block text-md-right breadcrumb-light">
                            <li><a href="/">Home</a></li>
                            <li className="active">{param.breadcrumb}</li>                            
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PageHeader;