import React, { Component } from "react";
import { Banner, Cta, Service, News, Contact, Leader } from "../components";
import {
  API_BANNERS,
  API_CASES,
  API_CLIENT_LANDING,
  API_NEWS_LANDING,
  API_SERVICES,
  API_MAIN,
  API_FAQ_LANDING,
  API_STEP_LANDING,
} from "../utils/constants";
import axios from "axios";

import bg_contact from "../assets/new_img/contact-background2.jpg";
import img_core_value from "../assets/new_img/new_core_value.jpg";
import img_mision from "../assets/new_img/new_mision.jpg";
import img_vision from "../assets/new_img/new_vision.jpg";
import img_people from "../assets/new_img/new_people.jpg";

export class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      banners: [],
      services: [],
      cases: [],
      clients: [],
      news: [],
      main: [],
      faq: [],
      step: [],
      leader: [],
    };
  }

  componentDidMount() {
    this.getBanner();
    this.getServices();
    this.getCases();
    this.getClient();
    this.getNews();
    this.getMain();
    this.getFaq();
    this.getStep();
  }

  getStep() {
    const headers = {};
    axios
      .get(API_STEP_LANDING, { headers: headers })
      .then((res) => {
        const step = res.data.strategies;
        this.setState({    });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getFaq() {
    const headers = {};
    axios
      .get(API_FAQ_LANDING, { headers: headers })
      .then((res) => {
        const faq = res.data.faqs;
        this.setState({ faq });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getNews() {
    const headers = {};
    axios
      .get(API_NEWS_LANDING, { headers: headers })
      .then((res) => {
        const news = res.data.news;
        this.setState({ news });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getClient() {
    const headers = {};
    axios
      .get(API_CLIENT_LANDING, { headers: headers })
      .then((res) => {
        const clients = res.data.clients;
        this.setState({ clients });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getCases() {
    const headers = {};
    axios
      .get(API_CASES, { headers: headers })
      .then((res) => {
        const cases = res.data.projects;
        this.setState({ cases });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getBanner() {
    const headers = {};
    axios
      .get(API_BANNERS, { headers: headers })
      .then((res) => {
        const banners = res.data.sliders;
        this.setState({ banners });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getServices() {
    const headers = {};
    axios
      .get(API_SERVICES, { headers: headers })
      .then((res) => {
        const services = res.data.services;
        this.setState({ services });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { banners, services, news, main, leader } = this.state;    
    return (
      <main>
        <div role="main" className="main">
          <Banner data={banners} />
          <Cta data={main} />
          {/* <About data={main} /> */}
          <Service data={services} main={main} />

          {/* <section className="custom-section-padding">
                        <div className="container">
                        <div className="row mb-3">
                            <div className="col-lg-6">
                                <h2 className="font-weight-bold text-color-dark">- Cooperation Process Flow</h2>
                                <Step data={step} />
                            </div>
                            <div className="col-lg-6">
                            <h2 className="font-weight-bold text-color-dark">- Frequently asked questions</h2>
                                <Faq data={faq} />
                            </div>
                        </div>
                        </div>
                    </section> */}

          <section
            className="section section-text-light section-background m-0"
            style={{
              background: `url(${bg_contact})`,
              backgroundSize: "cover",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col">
                  <h1>
                    <font color="black">{main.ABOUT}</font>
                  </h1>
                  <p align="justify">{main.desc_about}</p>
                </div>
              </div>
              <div
                className="row"
                style={{ marginTop: "30px", marginBottom: "30px" }}
              >
                <div className="col-lg-3">
                  <a
                    href={"/visi"}
                    className="text-decoration-none appear-animation"
                    data-appear-animation="fadeInUp"
                    data-appear-animation-delay="0"
                  >
                    <div className="feature-box custom-feature-box feature-box-style-2 text-center">
                      <img
                        src={img_vision}
                        style={{ height: "250px" }}
                        alt=""
                      />
                    </div>
                  </a>
                </div>
                <div className="col-lg-3">
                  <a
                    href={"/misi"}
                    className="text-decoration-none appear-animation"
                    data-appear-animation="fadeInUp"
                    data-appear-animation-delay="0"
                  >
                    <div className="feature-box custom-feature-box feature-box-style-2 text-center">
                      <img
                        src={img_mision}
                        style={{ height: "250px" }}
                        alt=""
                      />
                    </div>
                  </a>
                </div>
                <div className="col-lg-3">
                  <a
                    href={"/person"}
                    className="text-decoration-none appear-animation"
                    data-appear-animation="fadeInUp"
                    data-appear-animation-delay="0"
                  >
                    <div className="feature-box custom-feature-box feature-box-style-2 text-center">
                      <img
                        src={img_core_value}
                        style={{ height: "250px" }}
                        alt=""
                      />
                    </div>
                  </a>
                </div>
                <div className="col-lg-3">
                  <a
                    href={"/value"}
                    className="text-decoration-none appear-animation"
                    data-appear-animation="fadeInUp"
                    data-appear-animation-delay="0"
                  >
                    <div className="feature-box custom-feature-box feature-box-style-2 text-center">
                      <img
                        src={img_people}
                        style={{ height: "250px" }}
                        alt=""
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 text-left">
                  {/* <Link to="/about" className="btn btn-outline custom-border-width btn-primary custom-border-radius font-weight-semibold text-uppercase mb-3">READ MORE</Link> */}
                  <a
                    href="/about"
                    className="c-button btn btn-primary btn-modern font-weight-bold text-2 py-3 btn-px-4 appear-animation"
                    data-appear-animation="fadeInUpShorter"
                    data-appear-animation-delay="1500"
                  >
                    {main.read_more}
                  </a>
                </div>
              </div>
            </div>
          </section>

          {/* <Case data={cases} /> */}
          {/* <Client data={clients} /> */}
          {/* <CareerCta data={main} /> */}
          <Leader data={leader} />
          <News data={news} main={main} />
          <Contact data={main} />
        </div>
      </main>
    );
  }
}

export default Home;
