import React, { Component } from "react";
import { PageHeader, CareerPageBody, Contact } from "../components";
import axios from "axios";
import { API_SUBMIT_LAMARAN, API_MAIN } from "../utils/constants";
import { careers as car, sub_careers, submit_now } from "../utils/string";

export class Upload_cv extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileInput = React.createRef();
    this.state = {
      slug: "",
      main: [],
      selectedFile: null,
      message: "",
    };
  }

  componentDidMount() {
    this.getMain();
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);

    const data = new FormData();

    data.append("email", formData.get("email"));
    data.append("name", formData.get("name"));
    data.append("position", formData.get("position"));
    data.append("message", formData.get("message"));
    data.append("userfile", this.fileInput.current.files[0]);

    axios
      .post(API_SUBMIT_LAMARAN, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        // var res = JSON.stringify({error:true,msg:"Thank you for the message you sent, we will immediately respond to the message."});
        // var data = JSON.parse(res)
        console.log(res);
        if (res.data.error) {
          const message = (
            <div className="contact-form-error alert alert-danger mt-4">
              <strong>Error!</strong> {res.data.msg}
              <span className="mail-error-message text-1 d-block" />
            </div>
          );
          this.setState({ message });
        } else {
          const message = (
            <div className="contact-form-success alert alert-success mt-4">
              <strong>Success!</strong> {res.data.msg}
            </div>
          );
          this.setState({ message });
        }
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { main, message } = this.state;
    const param = {
      title: car[window.lang],
      subtitle: sub_careers[window.lang],
      breadcrumb: car[window.lang],
    };
    return (
      <main>
        <div role="main" className="main">
          <PageHeader param={param} />
          <section
            className="flat-row page-careers"
            style={{ marginTop: "50px" }}
          >
            <div className="container">
              <div
                className="careers-open "
                style={{ backgroundColor: "#fff" }}
              >
                <div className="info-apply   pt-3 pb-3">
                  <h1 style={{ marginBottom: "50px" }}>{main.upload_cv}</h1>
                  <div className="message">{message}</div>
                  <form
                    onSubmit={this.handleSubmit}
                    className="custom-contact-form-style-1 contactform style4  clearfix"
                    enctype="multipart/form-data"
                  >
                    <div className="form-row">
                      <div className="form-group col">
                        <div className="custom-input-box">
                          <i className="icon-user icons text-color-primary" />
                          <input
                            type="text"
                            data-msg-required="Please enter your name."
                            maxLength={100}
                            className="form-control"
                            name="name"
                            placeholder={main.name}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col">
                        <div className="custom-input-box">
                          <i className="icon-envelope icons text-color-primary" />
                          <input
                            type="email"
                            data-msg-required="Please enter your email address."
                            data-msg-email="Please enter a valid email address."
                            maxLength={100}
                            className="form-control"
                            name="email"
                            placeholder={main.email}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col">
                        <div className="custom-input-box">
                          <i className="icon-list icons text-color-primary" />
                          <input
                            type="position"
                            data-msg-required="Please enter your position ."
                            data-msg-position="Please enter a valid position."
                            maxLength={100}
                            className="form-control"
                            name="position"
                            placeholder={main.position}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col">
                        <div className="custom-input-box">
                          <i className="icon-bubble icons text-color-primary" />
                          <textarea
                            maxLength={5000}
                            data-msg-required="Please enter your message."
                            rows={10}
                            className="form-control"
                            name="message"
                            placeholder={main.message}
                            required
                            defaultValue={""}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-md-3">
                        <div className="form-group">
                          <span className="flat-input">
                            <input
                              type="file"
                              name="userfile"
                              id="userfile"
                              ref={this.fileInput}
                            />
                          </span>
                          <span>(File types: PDF Max-size: 2 MB)</span>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <input
                            type="submit"
                            name="submit"
                            value={submit_now[window.lang]}
                            className="btn btn-outline custom-border-width btn-primary custom-border-radius font-weight-semibold text-uppercase mb-4"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <div className="blank"></div>
        </div>
      </main>
    );
  }
}

export default Upload_cv;
