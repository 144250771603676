import React from "react"

const Footer = ({ menus }) => {
    return (
        <footer id="footer">
            <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center pb-4">
                <p className="font-white">2021 © <span className="text-color-light">Pro Visioner</span> - Copyright All Rights Reserved</p>
                </div>
            </div>
            </div>
        </footer>
    )
}

export default Footer;