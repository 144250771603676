import React, { Component } from 'react'
import { PageHeader, PageBody, Contact }  from '../components';
import { API_MAIN } from "../utils/constants";
import { about_us, mision} from '../utils/string'
import axios from "axios";

export class Misi extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: [],
            main:[]
        };
      }

    componentDidMount(){
        const headers = {}
        axios
        .get(API_MAIN, {headers : headers})
        .then((res) => {
            const content = res.data.main.MISI;
            const main = res.data.main;
            this.setState({ main });
            this.setState({ content });
        })
        .catch((error) => {
            console.log("Error yaa ", error);
        });
    }

    render() {
        const { content,main } = this.state;
        const header = {
            title: about_us[window.lang],
            subtitle: mision[window.lang],
            breadcrumb: mision[window.lang]
        }
        return (
            <main>
                <div role="main" className="main">
                    <PageHeader param={header} />
                    <PageBody param={content} />
                    <Contact data={main} />
                </div>
            </main>
        )
    }
}

export default Misi
