import React, { Component } from "react";
import { PageHeaderNoSubtitle, ServicePageBody, Contact } from "../components";
import axios from "axios";
import {
  API_DETAIL_SERVICE_BYID,
  API_DETAIL_SERVICE,
  API_MAIN,
} from "../utils/constants";

export class Detail_service extends Component {
  constructor(props) {
    super(props);

    this.state = {
      detail: [],
      detail2: [],
      slug: "",
      main: [],
    };
  }

  componentDidMount() {
    this.getMain();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      this.getData(this.props.match.params.slug);
    }
    this.getMain();
  }

  getData(slug) {
    const headers = {};
    axios
      .get(API_DETAIL_SERVICE + "?slug=" + slug, { headers: headers })
      .then((res) => {
        const detail = res.data.services;
        this.setState({ detail });
        if (this.state.main.LANG) {
          if (detail.LANG != this.state.main.LANG) {
            if (detail.LANG_REF == 0) {
              window.location.href = "/";
              // console.log("0");
            } else {
              axios
                .get(API_DETAIL_SERVICE_BYID + "?id=" + detail.LANG_REF, {
                  headers: headers,
                })
                .then((res) => {
                  const detail2 = res.data.services;
                  this.setState({ detail2 });
                  window.location.href = "/service/" + detail2.SLUG;
                });
              // console.log(detail.LANG_REF);
            }
          } 
          // else {
          //   console.log("LANG sama");
          // }
        } 
        // else {
        //   console.log("LANG UNDIFINED");
        // }
      })
      .catch((error) => {
        //console.log("Error yaa ", error);
      });
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
        this.getData(this.props.match.params.slug);
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { detail, main } = this.state;
    const param = {
      title: detail.TITLE,
      subtitle: detail.SUB_TITLE,
      breadcrumb: detail.TITLE,
    };
    return (
      <main>
        <div role="main" className="main">
          <PageHeaderNoSubtitle param={param} />
          <ServicePageBody param={detail} />
          <Contact data={main} />
        </div>
      </main>
    );
  }
}

export default Detail_service;
