import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import React, { Component , lazy , Suspense} from "react";

import {
  Home,
  About_page,
  // Detail_service,
  // Detail_news,
  // Detail_career,
  // Detail_page,
  // Detail_person,
  // Detail_events,
  // Service_page,
  // Case_page,
  // Career_page,
  // Tax_page,
  // Contact_page,
  // Detail_case,
  // Upload_cv,
  // Kuisioner,
  // Transfer_pricing,
  // Thank,
  // History,
  // Visi,
  // Misi,
  // Value,
  // Person
} from "./pages";
import { Footer, Header, RouteChangeTracker } from "./components";
import {
  API_SERVICES,
  API_CASES,
  API_MAIN,
  API_PAGES,
} from "./utils/constants";
import axios from "axios";
import ReactGa from "react-ga4";

ReactGa.initialize("G-YLNT21VM5Y");
// ReactGa.initialize("UA-197052180-1");

const History = lazy(() => import('./pages/History'));
const Visi = lazy(() => import('./pages/Visi'));
const Misi = lazy(() => import('./pages/Misi'));
const Value = lazy(() => import('./pages/Value'));
const Person = lazy(() => import('./pages/Person'));
const Detail_service = lazy(() => import('./pages/Detail_service'));
const Detail_news = lazy(() => import('./pages/Detail_news'));
const Detail_career = lazy(() => import('./pages/Detail_career'));
const Detail_page = lazy(() => import('./pages/Detail_page'));
const Detail_person = lazy(() => import('./pages/Detail_person'));
const Detail_events = lazy(() => import('./pages/Detail_events'));
const Service_page = lazy(() => import('./pages/Service_page'));
const Case_page = lazy(() => import('./pages/Case_page'));
const Career_page = lazy(() => import('./pages/Career_page'));
const Tax_page = lazy(() => import('./pages/Tax_page'));
const Contact_page = lazy(() => import('./pages/Contact_page'));
const Detail_case = lazy(() => import('./pages/Detail_case'));
const Upload_cv = lazy(() => import('./pages/Upload_cv'));
const Kuisioner = lazy(() => import('./pages/Kuisioner'));
const Transfer_pricing = lazy(() => import('./pages/Transfer_pricing'));
const Thank = lazy(() => import('./pages/Thank'));


const renderLoader = () => <p>Loading</p>;

export default class App extends Component {
  constructor(props) {
    super(props);
    window.lang = "id";

    this.state = {
      services: [],
      cases: [],
      main: [],
      pages: [],
      loading: true,
    };
  }

  componentDidMount() {
    // this.fakeRequest().then(() => {
    //   const el = document.querySelector(".loader-container");
    //   if (el) {
    //     el.remove(); // removing the spinner element
    //     this.setState({ loading: false }); // showing the app
    //   }
    // });
    this.getMain();
    this.getServices();
    // this.getCases();
    this.getPages();
  }

  // fakeRequest = () => {
  //   return new Promise((resolve) => setTimeout(() => resolve(), 2500));
  // };

  getPages() {
    const headers = {};
    axios
      .get(API_PAGES, { headers: headers })
      .then((res) => {
        const pages = res.data.pages;
        this.setState({ pages });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
        window.lang = res.data.main.LANG;
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  // getCases() {
  //   const headers = {};
  //   axios
  //     .get(API_CASES, { headers: headers })
  //     .then((res) => {
  //       const cases = res.data.projects;
  //       this.setState({ cases });
  //     })
  //     .catch((error) => {
  //       console.log("Error yaa ", error);
  //     });
  // }

  getServices() {
    const headers = {};
    axios
      .get(API_SERVICES, { headers: headers })
      .then((res) => {
        const services = res.data.services;
        this.setState({ services });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    // if (this.state.loading) {
    //   return null; //app is not ready (fake request is in process)
    // }
    const { services, main, pages } = this.state;
    return (
      <BrowserRouter>
        <RouteChangeTracker />
        <Header services={services} main={main} pages={pages} />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/about" component={About_page} exact />
            <Suspense fallback={<div>Loading...</div>}>   
              <Route path="/history" component={History} exact />
              <Route path="/visi" component={Visi} exact />
              <Route path="/misi" component={Misi} exact />
              <Route path="/value" component={Value} exact />
              <Route path="/person" component={Person} exact />
              <Route path="/person/:slug" component={Detail_person} exact />
              <Route path="/service" component={Service_page} exact />
              <Route
                path="/service/:slug"
                component={Detail_service}
                exact={true}
              />
              <Route path="/case" component={Case_page} exact />
              <Route path="/case/:slug" component={Detail_case} exact />
              <Route path="/career" component={Career_page} exact />
              <Route path="/career/:slug" component={Detail_career} exact />
              <Route path="/uploadcv" component={Upload_cv} exact />
              <Route path="/pages/:slug" component={Detail_page} exact />
              <Route
                path="/events/:slug/:idpeserta/:kolega"
                component={Detail_events}
                exact
              />
              <Route
                path="/TaxService/:no/:slug"
                component={Transfer_pricing}
                exact
              />
              <Route
                path="/TransferPricing/:no/:slug"
                component={Kuisioner}
                exact
              />
              <Route path="/thank" component={Thank} exact />
              <Route path="/tax" component={Tax_page} exact />
              <Route path="/tax/:slug" component={Detail_news} exact />
              <Route path="/tax/page/:page" component={Tax_page} exact />
              <Route path="/contact" component={Contact_page} exact />
            </Suspense>
        </Switch>
        <Footer />
      </BrowserRouter>
    );
  }
}
