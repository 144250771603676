import React from "react";
import { getYear, getDay, getMonth } from "../utils/util";
import { insight, read_more } from "../utils/string";

const News = ({ data, main }) => {
  return (
    <section className="custom-section-padding">
      <div className="container">
        <div className="row">
          <div className="col">
            {/* <h2 className="font-weight-bold text-color-dark">- Highlight News</h2> */}
            <h2> {main.insight}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <br></br>
          </div>
        </div>
        <div className="row">
          {data &&
            data.map((d, i) => (
              <article className="blog-post col-lg-4" key={d.ID}>
                <div className="row">
                  <div className="col-sm-8 col-lg-5">
                    <div className="blog-post-image-wrapper">
                      <img
                        src={d.FULL_PATH_IMAGE}
                        alt="asdas"
                        className="img-fluid mb-4"
                      />
                      <span className="blog-post-date bg-color-primary text-color-light font-weight-bold">
                        {getDay(d.DATE_NEWS)}
                        <span className="month-year font-weight-light">
                          {getMonth(d.DATE_NEWS)}-{getYear(d.DATE_NEWS)}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-7 mb-5">
                    <h4> {d.TITLE}</h4>
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: d.DESC_NEWS?.replace(
                            /<[^>]+>/g,
                            ""
                          ).substring(0, 150),
                        }}
                      ></span>
                      ...
                    </p>
                    <hr className="solid" />
                    <a
                      href={"/tax/" + d.SLUG}
                      className="btn btn-outline custom-border-width btn-primary custom-border-radius font-weight-semibold text-uppercase mt-4"
                      title="Read More"
                    >
                      {main.read_more}
                    </a>
                  </div>
                </div>
              </article>
            ))}
        </div>
      </div>
    </section>
  );
};

export default News;
