import React from "react"
import { getYear, getDay, getMonth } from "../utils/util"

const NewsBody = ({param}) => {
    return (
        <div className="container py-5">
            <div className="row">
                <div className="col-lg-8">                                        
                    <p align="justify">
                        <div dangerouslySetInnerHTML={ { __html: param.DESC_CAREER } }></div>
                    </p>
                </div>
                <div className="col-lg-4">                    
                    <img src={param.FULL_PATH_IMAGE} className="img-fluid mb-4" alt="" />      
                    <div className="post-infos d-flex">
                        <span className="info posted-by">
                            Posted by :
                            <span className="post-author font-weight-semibold text-color-dark">
                                Admin pro-visioner
                            </span>
                        </span>                                    
                    </div>                             
                    <span className="month-year font-weight-light">
                        Deadline Date : {getDay(param.DATE_CAREER)} {getMonth(param.DATE_CAREER)} {getYear(param.DATE_CAREER)}                                                 
                    </span>
                    <div className="post-infos d-flex">
                        <span className="info posted-by">
                            Tags :
                            <span className="post-author font-weight-semibold text-color-dark">
                                {param.TAG}
                            </span>
                        </span>                                    
                    </div>      
                    <a className="btn btn-outline custom-border-width btn-primary custom-border-radius font-weight-semibold text-uppercase mt-5" href="/uploadcv" title="Read More">APPLY</a>                       
                </div>                                               
            </div>
        </div>
    )
}

export default NewsBody;