import React, { Component } from 'react'
import { PageHeader, Contact, Leader}  from '../components';
import { API_MAIN, API_PERSON_LEADER, API_PERSON_EXPERT} from "../utils/constants";
import { about_us,  people_desc } from '../utils/string'
import axios from "axios";

export class Person extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: [],
            leader: [],
            expert: [],
            main: []
        };
      }

    componentDidMount(){
        this.getMain();
        this.getPersonLeader();
        this.getPersonExpert();
    }

    getMain(){
        const headers = {}
        axios
        .get(API_MAIN, {headers : headers})
        .then((res) => {
            const main = res.data.main;
            this.setState({ main });
        })
        .catch((error) => {
            console.log("Error yaa ", error);
        });
    }

    getPersonExpert(){
        const headers = {}
        axios
        .get(API_PERSON_EXPERT, {headers : headers})
        .then((res) => {
            const expert = res.data.person;
            this.setState({ expert });
        })
        .catch((error) => {
            console.log("Error yaa ", error);
        });
    }

    getPersonLeader(){
        const headers = {}
        axios
        .get(API_PERSON_LEADER, {headers : headers})
        .then((res) => {
            const leader = res.data.person;
            this.setState({ leader });
        })
        .catch((error) => {
            console.log("Error yaa ", error);
        });
    }

    render() {
        const { main,expert,leader} = this.state;
        const header = {
            title: about_us[window.lang],
            subtitle: people_desc[window.lang],
            breadcrumb: people_desc[window.lang]
        }
        return (
            <main>
                <div role="main" className="main">
                    <PageHeader param={header} />
                    <Leader param={leader} />
                    <section className="section-secondary custom-section-padding">
                    <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h2>Experts</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="row team-list sort-destination" data-sort-id="team">
                                        {expert &&
                                        expert.map((d) => (
                                            <div key={d.ID} className="col-md-3 isotope-item leadership" >
                                                <div className="team-item mb-3 p-0 pb-2">
                                                    <a href={"/person/"+d.SLUG} className="text-decoration-none">
                                                    <span className="image-wrapper">
                                                        <img src={d.FULL_PATH_IMAGE} width="100%" alt="" className="img-fluid" />
                                                    </span>
                                                    </a>
                                                    <div className="team-infos" style={{height:'150px'}}>
                                                        {/* <div className="share">
                                                            <i className="fas fa-share-alt" />
                                                            <div className="share-icons bg-color-light">
                                                            <a href="/#" className="text-decoration-none" title="Share on Facebook"><i className="fab fa-facebook-f" /></a>
                                                            <a href="/#" className="text-decoration-none" title="Share on Instagram"><i className="fab fa-instagram" /></a>
                                                            <a href="/#" className="text-decoration-none" title="Share on Linkedin"><i className="fab fa-linkedin-in" /></a>
                                                            </div>
                                                        </div> */}
                                                        <a href={"/person/"+d.SLUG} className="text-decoration-none">
                                                            <span className="team-member-name text-color-dark font-weight-semibold text-4">{d.NAME}</span>
                                                            <span className="team-member-desc font-weight-light">{d.POSITION}</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Contact data={main} />
                </div>
            </main>
        )
    }
}

export default Person
