import React, { Component } from 'react'
import { PageHeader, CareerPageBody, Contact }  from '../components';
import axios from "axios";
import { API_CAREER , API_MAIN} from '../utils/constants';
import { careers as car, sub_careers } from "../utils/string";

export class Career_page extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            careers: [],
            main: []
        };
      }

    componentDidMount(){
        const headers = {}
        axios
        .get(API_CAREER, {headers : headers})
        .then((res) => {
            const careers = res.data.careers;
            this.setState({ careers });
        })
        .catch((error) => {
            console.log("Error yaa ", error);
        });
        this.getMain();
    }

    getMain(){
        const headers = {}
        axios
        .get(API_MAIN, {headers : headers})
        .then((res) => {
            const main = res.data.main;
            this.setState({ main });
        })
        .catch((error) => {
            console.log("Error yaa ", error);
        });
    }

    render() {
        const { careers , main} = this.state
        const param = {
            title: car[window.lang],
            subtitle: sub_careers[window.lang],
            breadcrumb: car[window.lang]
        }
        return (
            <main>
                <div role="main" className="main">
                    <PageHeader param={param} />
                    <CareerPageBody param={careers} />
                    <Contact data={main} />
                </div>
            </main>
        )
    }
}

export default Career_page
