import React from "react";
import { Link } from "react-router-dom";
import { SearchBar, Language } from "../components";
import { useState } from "react";
import {
  about,
  careers,
  contact,
  core,
  home,
  industries,
  insightMenu,
  mision,
  people,
  service,
  vision,
} from "../utils/string";

const Header = ({ services, main, pages }) => {
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = useState(query || "");
  // Cookies.set('setting_web', 'piko')
  // console.log(Cookies.get())

  return (
    <div>
      <header
        id="header"
        className="header-transparent header-transparent-dark-bottom-border "
        data-plugin-options="{'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true}"
      >
        {/* <header id="header" className="header-transparent header-transparent-dark-bottom-border header-effect-shrink" data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyChangeLogo': true, 'stickyStartAt': 30, 'stickyHeaderContainerHeight': 70}"> */}
        <div className="header-body border-top-0 bg-dark box-shadow-none">
          <div style={{ backgroundColor: "#f57f22", height: "20px" }}></div>
          <div className="header-container container">
            <div className="header-row">
              <div className="header-column">
                <div className="header-row">
                  <div className="header-logo">
                    <a href="/">
                      <img
                        className="pt-2"
                        src={main.FULLPATH_LOGO_CLIENT}
                        style={{ width: "120px" }}
                        alt="logo"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="header-column justify-content-end">
                <SearchBar
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                />
                <div className="header-row" style={{ marginTop: "30px" }}>
                  <div className="header-nav header-nav-links header-nav-dropdowns-dark header-nav-light-text order-2 order-lg-1">
                    <div className="header-nav-main header-nav-main-mobile-dark header-nav-main-square header-nav-main-dropdown-no-borders header-nav-main-effect-2 header-nav-main-sub-effect-1">
                      <nav className="collapse">
                        <ul className="nav nav-pills" id="mainNav">
                          <li>
                            <a className="" href="/">
                              {main.HOME}
                            </a>
                          </li>
                          <li className="dropdown dropdown-primary">
                            <Link
                              to="/service"
                              className="dropdown-toggle nav-link"
                            >
                              {" "}
                              {main.SERVICES}
                            </Link>
                            <ul className="dropdown-menu">
                              {services &&
                                services.map((d) => (
                                  <li key={d.ID}>
                                    {/* <a className="dropdown-item" href={"/service/"+d.SLUG}> {d.TITLE} </a> */}
                                    <Link
                                      to={"/service/" + d.SLUG}
                                      className="dropdown-item nav-link"
                                    >
                                      {" "}
                                      {d.TITLE}
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                          </li>
                          <li className="dropdown dropdown-primary">
                            {/* <a className="dropdown-toggle nav-link" href="/about">
                                                            {about[window.lang]}
                                                        </a> */}
                            <Link
                              to="/about"
                              className="dropdown-toggle nav-link"
                            >
                              {" "}
                              {main.ABOUT}
                            </Link>
                            <ul className="dropdown-menu">
                              {/* <li>
                                                                <Link to="/history" className="dropdown-item"> History</Link>
                                                            </li> */}
                              <li>
                                <Link to="/visi" className="dropdown-item">
                                  {" "}
                                  {main.VISI_}
                                </Link>
                              </li>
                              <li>
                                <Link to="/misi" className="dropdown-item">
                                  {" "}
                                  {main.MISI_}
                                </Link>
                              </li>
                              <li>
                                <Link to="/value" className="dropdown-item">
                                  {" "}
                                  {main.CORE_VALUE}
                                </Link>
                              </li>
                              <li>
                                <Link to="/person" className="dropdown-item">
                                  {" "}
                                  {main.PEOPLE}
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            {/* <a className="nav-link" href="/case">
                                                         {industries[window.lang]}
                                                        </a> */}
                            <Link to="/case" className="dropdown-item">
                              {" "}
                              {main.INDUSTRIES}
                            </Link>
                          </li>
                          {/* <li>
                                                        <a className="nav-link" href="demo-business-consulting-tertimonials.html">
                                                            Testimonials
                                                        </a>
                                                    </li> */}
                          <li>
                            <Link to="/career" className="dropdown-item">
                              {" "}
                              {main.CAREERS}
                            </Link>
                          </li>
                          {/* <li className="dropdown dropdown-primary">
                                                        <a className="dropdown-toggle nav-link">
                                                            Pages
                                                        </a>
                                                        <ul className="dropdown-menu">
                                                            {pages && pages.map((d) => (
                                                            <li key={d.ID}>
                                                                <Link to={"/pages/"+d.SLUG} className="dropdown-item"> {d.TITLE}</Link>
                                                            </li>
                                                            ))}
                                                        </ul>
                                                    </li> */}
                          {/* <li>
                                                        <a className="nav-link" href="#">
                                                            Client Portal
                                                        </a>
                                                    </li> */}
                          {/* <li>
                                                        <a className="nav-link" href="demo-business-consulting-contact-us.html">
                                                            Documents
                                                        </a>
                                                    </li> */}
                          <li>
                            <Link to="/tax/page/1" className="dropdown-item">
                              {" "}
                              {main.NEWS}
                            </Link>
                          </li>
                          <li>
                            <Link to="/contact" className="dropdown-item">
                              {" "}
                              {main.CONTACTS}
                            </Link>
                          </li>
                          <Language />
                        </ul>
                      </nav>
                    </div>
                    <button
                      className="btn header-btn-collapse-nav"
                      data-toggle="collapse"
                      data-target=".header-nav-main nav"
                    >
                      <i className="fas fa-bars"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
