import React from "react";
import { BsChevronLeft } from "react-icons/bs";

const Banner = ({data}) => {
    return (
        <div className="owl-carousel owl-carousel-light owl-carousel-light-init-fadeIn owl-theme manual dots-inside dots-horizontal-center show-dots-hover show-dots-xs show-dots-sm show-dots-md dots-light custom-nav-arrows-1 nav-dark nav-md nav-font-size-md show-nav-hover mb-0" data-plugin-options="{'autoplayTimeout': 3500}" style={{ height: '100vh' }}>
            <div className="owl-stage-outer">
                <div className="owl-stage">
                    {data &&
                    data.map((d) => (
                        <div key={d.ID} className="owl-item position-relative pt-5" style={{backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${d.FULL_PATH_IMAGE})`,backgroundSize: 'cover',backgroundPosition: 'center'}}>
                            <div className="container position-relative z-index-3 pb-5 h-100">
                                <div className="row align-items-center pb-5 h-100">
                                    <div className="col-md-6 col-sm-12" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)', marginTop: '100px'}}>
                                        <h1 style={{marginTop: '30px', marginLeft: '30px'}} className="custom-secondary-font text-color-light font-weight-extra-bold text-8 line-height-1 line-height-sm-3 mb-5 appear-animation" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="1500" data-plugin-options="{'minWindowWidth': 0}"><span className="custom-secondary-font text-4-5">{d.SUB_TITLE}</span></h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="owl-nav">
                <button type="button" role="presentation" className="owl-prev" style={{marginLeft: '-5px', width: '50px !important', height: '100px !important', opacity: '80%'}}><BsChevronLeft size={50} /></button>
                <button type="button" role="presentation" className="owl-next" style={{marginRight: '-5px', width: '50px !important', height: '100px !important', opacity: '80%'}}><BsChevronLeft size={50} /></button>
            </div>
        </div>
    )
}

export default Banner;