import React from "react";

const PageHeader2 = ({ param }) => {
  return (
    <section className="page-header page-header-modern bg-color-quaternary page-header-md custom-page-header">
      <div className="container" style={{ marginTop: "30px" }}>
        <div className="row mt-3">
          <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
            <h1 style={{ wordSpacing: "normal" }}>{param.title}</h1>
            <span className="d-block text-4">{param.subtitle}</span>
          </div>
          <div className="col-md-4 order-1 order-md-2 align-self-center">
            <ul className="breadcrumb d-block text-md-right breadcrumb-light">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href={param.breadcrumbLink}>{param.breadcrumb}</a>
              </li>
              <li className="active">{param.breadcrumb1}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageHeader2;
