import React from "react";
import { looking_for, partner, call_now, mail_now } from "../utils/string";

const Cta = ({ data }) => {
  return (
    <section className="looking-for custom-position-1 custom-md-border-top z-index-1">
      <div className="divider-cta"></div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-5 col-lg-5">
            <div className="looking-for-box">
              <h2>
                <span className="text-1 custom-secondary-font">
                  {data.looking_for}
                </span>
                <br />
                {data.partner}
              </h2>
            </div>
          </div>
          <div className="col-md-3 col-lg-3 d-flex mb-4 mb-md-0 justify-content-md-end">
            <a
              className="text-decoration-none"
              href={"tel:" + data.NO_TELP}
              target="_blank"
              title="Call Us Now"
              rel="noreferrer"
            >
              <span className="custom-call-to-action">
                <span className="action-title text-color-light">
                  {data.call_now}
                </span>
                <span className="action-info text-color-light">
                  <strong>{data.NO_TELP}</strong>
                </span>
              </span>
            </a>
          </div>
          <div className="col-md-4 col-lg-4 d-flex mb-4 mb-md-0 justify-content-md-end">
            <a
              className="text-decoration-none"
              href={"mailto:" + data.EMAIL}
              target="_blank"
              title="Email Us Now"
              rel="noreferrer"
            >
              <span className="custom-call-to-action">
                <span className="action-title text-color-light">
                  {data.mail_now}
                </span>
                <span className="action-info text-color-light">
                  <strong>{data.EMAIL}</strong>
                </span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta;
