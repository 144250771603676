import React, { Component } from "react";
import { PageHeader } from "../components";
import { API_MAIN, API_POST_MESSAGE } from "../utils/constants";
import axios from "axios";
import bg_contact from "../assets/img/page-contact/Contact_Us.jpg";
import GoogleMapReact from "google-map-react";
import {
  address,
  contact,
  send_a_message,
  sub_contact,
  phone,
  submit_now,
} from "../utils/string";
import ReCAPTCHA from "react-google-recaptcha";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export class Contact_page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      header: {
        title: contact[window.lang],
        subtitle: sub_contact[window.lang],
        breadcrumb: contact[window.lang],
      },
      main: [],
      message: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  static defaultProps = {
    center: {
      lat: -6.2925042,
      lng: 106.7827908,
    },
    zoom: 18,
  };

  componentDidMount() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  onChange(value) {
    console.log("Captcha value:", value);
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    if (data.get("g-recaptcha-response") != "") {
      var param = {
        email: data.get("email"),
        name: data.get("name"),
        message: data.get("message"),
      };

      const headers = {};
      axios
        .post(API_POST_MESSAGE, param, { headers: headers })
        .then((res) => {
          // var res = JSON.stringify({error:true,msg:"Thank you for the message you sent, we will immediately respond to the message."});
          // var data = JSON.parse(res)
          if (res.data.error) {
            const message = (
              <div className="contact-form-error alert alert-danger mt-4">
                <strong>Error!</strong> {res.data.msg}
                <span className="mail-error-message text-1 d-block" />
              </div>
            );
            this.setState({ message });
          } else {
            const message = (
              <div className="contact-form-success alert alert-success mt-4">
                <strong>Success!</strong> {res.data.msg}
              </div>
            );
            console.log(message);
            this.setState({ message });
          }
        })
        .catch((error) => {
          console.log("Error yaa ", error);
        });
    } else {
      const message = (
        <div className="contact-form-error alert alert-danger mt-4">
          <strong>Error!</strong> Please verify that you are a Human.
          <span className="mail-error-message text-1 d-block" />
        </div>
      );
      this.setState({ message });
    }
  }

  render() {
    const recaptchaRef = React.createRef();
    const { header, main, message } = this.state;
    return (
      <main>
        <div role="main" className="main">
          <PageHeader param={header} />
          <div className="container">
            <div className="row pt-1 pb-4 mb-3">
              <div className="col-lg-8">
                <h2 className="font-weight-bold text-color-dark">
                  {send_a_message[window.lang]}
                </h2>
                <form
                  onSubmit={this.handleSubmit}
                  className="contact-form custom-contact-form-style-1"
                >
                  <div className="message">{message}</div>
                  <input
                    type="hidden"
                    name="subject"
                    defaultValue="Contact Message Received"
                  />
                  <div className="form-row">
                    <div className="form-group col">
                      <div className="custom-input-box">
                        <i className="icon-user icons text-color-primary" />
                        <input
                          type="text"
                          data-msg-required="Please enter your name."
                          maxLength={100}
                          className="form-control"
                          name="name"
                          placeholder="Name*"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col">
                      <div className="custom-input-box">
                        <i className="icon-envelope icons text-color-primary" />
                        <input
                          type="email"
                          data-msg-required="Please enter your email address."
                          data-msg-email="Please enter a valid email address."
                          maxLength={100}
                          className="form-control"
                          name="email"
                          placeholder="Email*"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col">
                      <div className="custom-input-box">
                        <i className="icon-bubble icons text-color-primary" />
                        <textarea
                          maxLength={5000}
                          data-msg-required="Please enter your message."
                          rows={10}
                          className="form-control"
                          name="message"
                          placeholder="Message*"
                          required
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Lf6LB4eAAAAAGSXagUWhv0Rj6IuPwhamLTB1pOG"
                    // sitekey="6LcgCB4eAAAAAHNfU8JWGyLyTgwhDkjxrq-V8Zj_"
                    onChange={this.onChange}
                  />
                  <div className="form-row">
                    <div className="form-group col">
                      <input
                        type="submit"
                        value={submit_now[window.lang]}
                        className="btn btn-outline custom-border-width btn-primary custom-border-radius font-weight-semibold text-uppercase mb-4"
                        data-loading-text="Loading..."
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-4">
                <div className="row mb-4">
                  <div className="col">
                    <div className="feature-box feature-box-style-2">
                      <div className="feature-box-icon mt-1">
                        <i className="icon-envelope icons" />
                      </div>
                      <div className="feature-box-info">
                        <h2 className="font-weight-bold text-color-dark">
                          Email
                        </h2>
                        <p className="text-4">
                          <a
                            href={"mailto:" + main.EMAIL}
                            className="text-decoration-none"
                          >
                            {main.EMAIL}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <div className="feature-box feature-box-style-2">
                      <div className="feature-box-icon mt-1">
                        <i className="icon-phone icons" />
                      </div>
                      <div className="feature-box-info">
                        <h2 className="font-weight-bold text-color-dark">
                          {phone[window.lang]}
                        </h2>
                        <p className="text-4">
                          <a
                            href={"tel:" + main.NO_TELP}
                            className="text-decoration-none"
                          >
                            {main.NO_TELP}
                          </a>
                        </p>
                        <p className="text-4">
                          <a
                            href={"tel:" + main.NO_TELP2}
                            className="text-decoration-none"
                          >
                            {main.NO_TELP2}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <div className="feature-box feature-box-style-2">
                      <div className="feature-box-icon mt-1">
                        <i className="icon-location-pin icons" />
                      </div>
                      <div className="feature-box-info">
                        <h2 className="font-weight-bold text-color-dark">
                          {address[window.lang]}
                        </h2>
                        <p className="custom-opacity-font">
                          <font color="black">
                            <p className="text-2">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: main.ADDRESS,
                                }}
                              ></div>
                            </p>
                          </font>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              height: "500px",
              width: "100%",
              background: `url(${bg_contact}) no-repeat center center`,
              backgroundSize: "cover",
            }}
          >
            {/* <GoogleMapReact
                        bootstrapURLKeys={{key: "AIzaSyDS_ZqNLfFgRxdmyKhvU0Jo-3DB7f-tknY"}}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        >
                        <AnyReactComponent
                            lat={main.LAT}
                            lng={main.LON}
                            text="Pro Visioner"
                        />
                        </GoogleMapReact> */}
          </div>
        </div>
      </main>
    );
  }
}

export default Contact_page;
