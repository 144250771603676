import React, { Component } from 'react'
import { PageHeader, BlogPageBody, Contact }  from '../components';
import axios from "axios";
import { API_SERVICES, API_MAIN } from '../utils/constants';

export class Service_page extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            services: [],
            main: []
        };

        this.getMain();
      }

    componentDidMount(){
        const headers = {}
        axios
        .get(API_SERVICES, {headers : headers})
        .then((res) => {
            const services = res.data.services;
            this.setState({ services });
        })
        .catch((error) => {
            console.log("Error yaa ", error);
        });
    }

    getMain(){
        const headers = {}
        axios
        .get(API_MAIN, {headers : headers})
        .then((res) => {
            const main = res.data.main;
            this.setState({ main });
        })
        .catch((error) => {
            console.log("Error yaa ", error);
        });
    }
    
    render() {
        const { services , main} = this.state
        const param = {
            title: "Services",
            subtitle: "",
            breadcrumb: "Services"
        }
        return (
            <main>
                <div role="main" className="main">
                    <PageHeader param={param} />
                    <BlogPageBody param={services} />
                    <Contact data={main} />
                </div>
            </main>
        )
    }
}

export default Service_page
