import React from "react"

const PageBody = ({param}) => {
    return (
        <div className="container py-2">
            <div className="row">
                <div className="col-lg-12 pl-0">
                    <span align="justify">
                        <div dangerouslySetInnerHTML={ { __html: param } }></div>
                    </span>                    
                </div>
            </div>
        </div>
    )
}

export default PageBody;