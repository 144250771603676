import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Vendor CSS
import "./assets/vendor/bootstrap/css/bootstrap.min.css";
import "./assets/vendor/fontawesome-free/css/all.min.css";
import "./assets/vendor/animate/animate.compat.css";
import "./assets/vendor/simple-line-icons/css/simple-line-icons.min.css";
import "./assets/vendor/owl.carousel/assets/owl.carousel.min.css";
import "./assets/vendor/owl.carousel/assets/owl.theme.default.min.css";
import "./assets/vendor/magnific-popup/magnific-popup.min.css";
// Theme CSS
import "./assets/css/theme.css";
import "./assets/css/theme-elements.css";
import "./assets/css/theme-blog.css";
import "./assets/css/theme-shop.css";
// Demo CSS
import "./assets/css/demos/demo-business-consulting.css";
// Skin CSS
import "./assets/css/skins/skin-business-consulting.css";
// Theme Custom CSS
import "./assets/css/custom.css";

ReactDOM.render(
  <React.StrictMode>
    {/* <div class="loader-container">
      <div class="loader"></div>
    </div> */}
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
