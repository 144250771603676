import React from "react"
import { Step }  from '../components';

const ServicePageBody = ({param}) => {

    return (
        <div className="container">
            <div className="row pt-1 pb-4 mb-3">
                <div className="col-lg-12">
                    <span>{param.SUB_TITLE }</span>
                    <div dangerouslySetInnerHTML={ { __html: param.DESC_SERVICE } }></div>
                    {/* <Faq data={param.FAQ}/> */}
                </div>
                {/* <div className="col-lg-8"> */}
                    {/* <img src={param.FULL_PATH_IMAGE} className="img-fluid mb-4" alt="" /> */}
                    {/* <h4 className="font-weight-bold text-color-dark mt-3">- Our Strategy</h4> */}
                    {/* <Step data={param.STEP}/> */}
                {/* </div> */}
            </div>
        </div>
    )
}

export default ServicePageBody;