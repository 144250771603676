import React, { Component } from "react";
import { getYear, getDay, getMonth } from "../utils/util";
import { PageHeader, CareerPageBody, Contact } from "../components";
import axios from "axios";
import {
  API_GET_MONEV,
  API_SUBMIT_LAMARAN,
  API_UPDATE_MONEV,
  API_MAIN,
} from "../utils/constants";
import {
  careers as car,
  Questionnaire,
  JobSetup,
  Client,
  ClientContact,
  sub_careers,
  submit_now,
  SaranKomentar,
  ValueNilai,
  port_clause,
  port_clause_desc,
  agre_full,
  agre_not,
  agre_part,
  note_clause,
  port_clause_disclamer,
  industry_knowledge,
  service_feeback,
  question_1,
  question_2,
  business_delivery,
  question_3,
  question_4,
  lot_worse,
  worse,
  better,
  lot_better,
  question_5,
  question_6,
  question_7,
  question_8,
  question_9,
  question_10,
  service_satisfaction,
  ManagerAssist,
  tp_question_1,
  tp_question_1a,
  tp_question_1b,
  tp_question_1c,
  tp_question_1d,
  tp_question_1e,
  tp_question_1f,
  tp_question_1g,
  tp_question_1h,
  poor,
  good,
  fair,
  excellent,
  exceptional,
  very_unlikely,
  somewhat_unlikely,
  neither_likely_nor_unlikely,
  somewhat_likely,
  very_likely,
} from "../utils/string";

export class Transfer_pricing extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileInput = React.createRef();
    this.state = {
      no: "",
      slug: "",
      main: [],
      detail: [],
      service: [],
      client: [],
      clientContact: [],
      data: [],
      selectedFile: null,
      message: "",
    };
  }

  componentDidMount() {
    this.getMain();
    this.getData(this.props.match.params.no, this.props.match.params.slug);
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getData(no, slug) {
    const headers = {};
    axios
      .get(API_GET_MONEV + "/" + no + "/" + slug, { headers: headers })
      .then((res) => {
        const detail = JSON.parse(res.data.data.RAW_DATA);
        const service = detail.Service;
        const client = detail.Client;
        const clientContact = client.ClientContact;
        const data = res.data.data;

        this.setState({ detail });
        this.setState({ service });
        this.setState({ client });
        this.setState({ clientContact });
        this.setState({ data });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const element = document.getElementsByClassName(name);
    element[0].textContent =
      value.replace("0", "") == "" ? "0" : value.replace("0", "");
  };

  handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);

    const data = new FormData();
    data.append("tp_question_1a", formData.get("tp_question_1a"));
    data.append("tp_question_1b", formData.get("tp_question_1b"));
    data.append("tp_question_1c", formData.get("tp_question_1c"));
    data.append("tp_question_1d", formData.get("tp_question_1d"));
    data.append("tp_question_1e", formData.get("tp_question_1e"));
    data.append("tp_question_1f", formData.get("tp_question_1f"));
    data.append("tp_question_1g", formData.get("tp_question_1g"));
    data.append("tp_question_1h", formData.get("tp_question_1h"));
    data.append("form_layanan", formData.get("form_layanan"));
    data.append("no", formData.get("no"));
    data.append("client", formData.get("client"));
    // console.log(param);

    axios
      .post(API_UPDATE_MONEV, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.error) {
          const message = (
            <div className="contact-form-error alert alert-danger mt-4">
              <strong>Error!</strong> {res.data.data}
              <span className="mail-error-message text-1 d-block" />
            </div>
          );
          this.setState({ message });
        } else {
          const message = (
            <div className="contact-form-success alert alert-success mt-4">
              <strong>Success!</strong> {res.data.data}
            </div>
          );
          this.setState({ message });
          // setInterval(function () {
          //   window.location.href = "/Thank";
          // }, 2000);
        }
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { service, client, data, clientContact, detail, main, message } =
      this.state;
    const param = {
      title: Questionnaire[window.lang],
      subtitle: Questionnaire[window.lang],
      breadcrumb: Questionnaire[window.lang],
    };
    return (
      <main>
        <div role="main" className="main">
          <PageHeader param={param} />
          <section className="custom-section-padding">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h1> {Questionnaire[window.lang]}</h1>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <br></br>
                </div>
              </div>
              <div className="row">
                <article className="blog-post col-lg-4 mb-1">
                  <h4>{JobSetup[window.lang]}</h4>
                  <div className="mb-0">
                    <span>{data.JOBSETUP_NO}</span>
                  </div>
                  <div className="mb-0">
                    <span>{data.SERVICE_NAME}</span>
                  </div>
                  <div className="mb-0">
                    <span>{detail.JobSetupFiscalYears}</span>
                  </div>
                  <div className="mb-0">
                    <span>
                      {getDay(detail.JobSetupPeriodeStart)}{" "}
                      {getMonth(detail.JobSetupPeriodeStart)}{" "}
                      {getYear(detail.JobSetupPeriodeStart)} 
                      &nbsp;-&nbsp;&nbsp;
                      {getDay(detail.JobSetupPeriodeFinish)}{" "}
                      {getMonth(detail.JobSetupPeriodeFinish)}{" "}
                      {getYear(detail.JobSetupPeriodeFinish)}
                    </span>
                  </div>
                </article>
                <article className="blog-post col-lg-4 mb-1">
                  <h4>{Client[window.lang]}</h4>
                  <div className="mb-0">
                      <span>
                        {this.props.match.params.slug.replace(/-/g, " ").toUpperCase()}
                      </span>
                  </div>
                  {/* <div className="mb-0">
                    <span>{client.ClientWebsite}</span>
                  </div> */}
                  {/* <div className="mb-0">
                    <span>{client.ClientNpwpNo}</span>
                  </div> */}
                  <div className="mb-0">
                    <span>{client.ClientDesc}</span>
                  </div>
                </article>
                {/* <article className="blog-post col-lg-3 mb-1">
                  <h4>{ClientContact[window.lang]}</h4>
                  <div>
                    <div className="mb-0">
                      <span>{client.ContactName}</span>
                    </div>
                    <div className="mb-0">
                      <span>
                        <i className="icon-envelope icons"></i>&nbsp;&nbsp;
                        {client.ContactEmail}
                      </span>
                    </div>
                    <div className="mb-0">
                      <span>
                        <i className="icon-phone icons"></i>&nbsp;&nbsp;
                        {client.ContactPhone}
                      </span>
                    </div>
                  </div>
                </article> */}
                <article className="blog-post col-lg-4 mb-1">
                  <h4>{ManagerAssist[window.lang]}</h4>
                  <div>
                    <div className="mb-0">
                      <span>{data.MANAGE_ASSIST}</span>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </section>
          <section className="flat-row page-careers">
            <div className="container">
              <div
                className="careers-open "
                style={{ backgroundColor: "#fff" }}
              >
                <div className="info-apply pb-3">
                  {/* <h1>{main.upload_cv}</h1> */}
                  <form
                    onSubmit={this.handleSubmit}
                    className="custom-contact-form-style-1 contactform style4  clearfix"
                    encType="multipart/form-data"
                  >
                    <div className="form-row">
                      <div className="form-group col">
                        <div className="custom-input-box">
                          {/* <i className="icon-user icons text-color-primary" /> */}
                          <input
                            type="hidden"
                            data-msg-required="Please enter your name."
                            value={data.JOBSETUP_NO}
                            className="form-control"
                            name="no"
                            placeholder={main.name}
                            required
                          />
                          <input
                            type="hidden"
                            data-msg-required="Please enter your name."
                            value="2"
                            className="form-control"
                            name="form_layanan"
                            required
                          />
                          <input
                            type="hidden"
                            data-msg-required="Please enter your name."
                            value={this.props.match.params.slug}
                            className="form-control"
                            name="client"
                            placeholder={this.props.match.params.slug}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ro">
                      <div className="col mt-4">
                        <h4
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          {service_feeback[window.lang]}
                        </h4>
                        <div className="mb-0">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: tp_question_1[window.lang],
                            }}
                          ></span>
                        </div>
                        <table className="table">
                          <thead>
                            <tr>
                              <th width="100%"></th>
                              <th>
                                <h5>NA</h5>
                              </th>
                              <th>
                                <h5>{poor[window.lang]}</h5>
                              </th>
                              <th>
                                <h5>{fair[window.lang]}</h5>
                              </th>
                              <th>
                                <h5>{good[window.lang]}</h5>
                              </th>
                              <th>
                                <h5>{excellent[window.lang]}</h5>
                              </th>
                              <th>
                                <h5>{exceptional[window.lang]}</h5>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="transferpricing">
                            <tr>
                              <th>
                                <span>{tp_question_1a[window.lang]}</span>
                              </th>
                              {/* <div className="custom-input-box"> */}
                              <td></td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1a"
                                  value="20"
                                  required
                                />
                              </td>
                              <td>
                                {" "}
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1a"
                                  value="40"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1a"
                                  value="60"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1a"
                                  value="80"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1a"
                                  value="100"
                                  required
                                />
                              </td>
                              {/* </div> */}
                            </tr>
                            <tr>
                              <th>
                                <span>{tp_question_1b[window.lang]}</span>
                              </th>
                              {/* <div className="custom-input-box"> */}
                              <td></td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1b"
                                  value="20"
                                  required
                                />
                              </td>
                              <td>
                                {" "}
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1b"
                                  value="40"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1b"
                                  value="60"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1b"
                                  value="80"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1b"
                                  value="100"
                                  required
                                />
                              </td>
                              {/* </div> */}
                            </tr>
                            <tr>
                              <th>
                                <span>{tp_question_1c[window.lang]}</span>
                              </th>
                              {/* <div className="custom-input-box"> */}
                              <td></td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1c"
                                  value="20"
                                  required
                                />
                              </td>
                              <td>
                                {" "}
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1c"
                                  value="40"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1c"
                                  value="60"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1c"
                                  value="80"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1c"
                                  value="100"
                                  required
                                />
                              </td>
                              {/* </div> */}
                            </tr>
                            <tr>
                              <th>
                                <span>{tp_question_1d[window.lang]}</span>
                              </th>
                              {/* <div className="custom-input-box"> */}
                              <td></td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1d"
                                  value="20"
                                  required
                                />
                              </td>
                              <td>
                                {" "}
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1d"
                                  value="40"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1d"
                                  value="60"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1d"
                                  value="80"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1d"
                                  value="100"
                                  required
                                />
                              </td>
                              {/* </div> */}
                            </tr>
                            <tr>
                              <th>
                                <span>{tp_question_1e[window.lang]}</span>
                              </th>
                              {/* <div className="custom-input-box"> */}
                              <td></td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1e"
                                  value="20"
                                  required
                                />
                              </td>
                              <td>
                                {" "}
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1e"
                                  value="40"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1e"
                                  value="60"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1e"
                                  value="80"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1e"
                                  value="100"
                                  required
                                />
                              </td>
                              {/* </div> */}
                            </tr>
                            <tr>
                              <th>
                                <span>{tp_question_1f[window.lang]}</span>
                              </th>
                              {/* <div className="custom-input-box"> */}
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1f"
                                  value="na"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1f"
                                  value="20"
                                  required
                                />
                              </td>
                              <td>
                                {" "}
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1f"
                                  value="40"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1f"
                                  value="60"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1f"
                                  value="80"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1f"
                                  value="100"
                                  required
                                />
                              </td>
                              {/* </div> */}
                            </tr>
                            <tr>
                              <th>
                                <span>{tp_question_1g[window.lang]}</span>
                              </th>
                              {/* <div className="custom-input-box"> */}
                              <td></td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1g"
                                  value="20"
                                  required
                                />
                              </td>
                              <td>
                                {" "}
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1g"
                                  value="40"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1g"
                                  value="60"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1g"
                                  value="80"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="radioButton"
                                  type="radio"
                                  name="tp_question_1g"
                                  value="100"
                                  required
                                />
                              </td>
                              {/* </div> */}
                            </tr>
                          </tbody>
                        </table>
                        <p
                          className="mt-5"
                          dangerouslySetInnerHTML={{
                            __html: tp_question_1h[window.lang],
                          }}
                        ></p>
                        <div className="form-row mb-5  pb-4">
                          <div className="form-group col">
                            <div
                              className="custom-input-box"
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <input
                                type="range"
                                min={0}
                                max={50}
                                // value={5}
                                step={10}
                                className="form-control"
                                name="tp_question_1h"
                                onChange={this.handleChange}
                                required
                              />
                              <div className="indicator tp_question_1h">5</div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="form-row">
                          <div
                            className="form-group col pt-3 mb-5"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <div className="custom-input-box">
                              <input
                                type="radio"
                                className="radioButton"
                                name="tp_question_1h"
                                value="Very likely"
                                required
                              />
                              &nbsp;{very_likely[window.lang]}&nbsp;&nbsp;
                              <input
                                type="radio"
                                className="radioButton"
                                name="tp_question_1h"
                                value="Somewhat likely"
                                required
                              />
                              &nbsp;{somewhat_likely[window.lang]}&nbsp;&nbsp;
                              <input
                                type="radio"
                                className="radioButton"
                                name="tp_question_1h"
                                value="Neither likely nor unlikely"
                                required
                              />
                              &nbsp;{neither_likely_nor_unlikely[window.lang]}
                              &nbsp;&nbsp;
                              <input
                                type="radio"
                                className="radioButton"
                                name="tp_question_1h"
                                value="Somewhat unlikely"
                                required
                              />
                              &nbsp;{somewhat_unlikely[window.lang]}
                              &nbsp;&nbsp;
                              <input
                                type="radio"
                                className="radioButton"
                                name="tp_question_1h"
                                value="Very unlikely"
                                required
                              />
                              &nbsp;{very_unlikely[window.lang]}
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="message">{message}</div>
                    <div className="row justify-content-between mt-3">
                      <div className="col-md-2">
                        <div className="form-group">
                          <input
                            type="submit"
                            name="submit"
                            value={submit_now[window.lang]}
                            className="btn btn-outline custom-border-width btn-primary custom-border-radius font-weight-semibold text-uppercase mb-4"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <div className="blank"></div>
        </div>
      </main>
    );
  }
}

export default Transfer_pricing;
