import React from "react"

const PersonBody = ({param}) => {
    return (       
        <div class="container">
            <div class="row pt-1 pb-4 mb-3">
                <div class="col">
                    <div class="row mb-4">
                        <div class="col-md-3 text-center">
                            <div class="team-item">
                                <span class="image-wrapper">
                                    <img src={param.FULL_PATH_IMAGE}  alt="" class="img-fluid" />
                                </span>
                                <div class="team-infos">
                                    {/* <div class="share">
                                        <i class="fas fa-share-alt"></i>
                                        <div class="share-icons bg-color-light">
                                            <a href="/#" class="text-decoration-none" title="Share on Facebook"><i class="fab fa-facebook-f"></i></a>
                                            <a href="/#" class="text-decoration-none" title="Share on Instagram"><i class="fab fa-instagram"></i></a>
                                            <a href="/#" class="text-decoration-none" title="Share on Linkedin"><i class="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <h1 class="mt-0 mb-0">- {param.NAME}</h1>
                            <p class="lead ml-4 pt-1">{param.POSITION}</p>
                            <div class="col">
                                <p align="justify">
                                    {<div dangerouslySetInnerHTML={ { __html: param.DESC_PERSON } }></div>}
                                </p>
                            </div>
                        </div>
                    </div>                   
                </div>
            </div>
        </div>
    )
}

export default PersonBody;