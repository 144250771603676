import React, { Component } from 'react'
import { PageHeader, PageBody, Contact}  from '../components';
import { API_MAIN } from "../utils/constants";
import axios from "axios";

export class History extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            header: {
                title: 'About Us',
                subtitle: 'History',
                breadcrumb: 'History'
            },
            content: [],
            main: []
        };
      }
    
    componentDidMount(){
        const headers = {}
        axios
        .get(API_MAIN, {headers : headers})
        .then((res) => {
            const content = res.data.main.HISTORY;
            const main = res.data.main;
            this.setState({ main });
            this.setState({ content });
        })
        .catch((error) => {
            console.log("Error yaa ", error);
        });
    }

    render() {
        const { header,content,main } = this.state;
        return (
            <main>
                <div role="main" className="main">
                    <PageHeader param={header} />
                    <PageBody param={content} />
                    <Contact data={main} />
                </div>
            </main>
        )
    }
}

export default History
