import React, { Component } from 'react'
import { PageHeader,Contact }  from '../components';
import axios from "axios";
import { API_CASES, API_CASE_TAGS, API_MAIN } from '../utils/constants';
import { industries } from '../utils/string'

export class Case_page extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            cases: [],
            tags: [],
            main: []
        };
      }

    componentDidMount(){
        this.getCases();
        this.getTags();
        this.getMain();
    }

    getCases(){
        const headers = {}
        axios
        .get(API_CASES, {headers : headers})
        .then((res) => {
            const cases = res.data.projects;
            this.setState({ cases });
        })
        .catch((error) => {
            console.log("Error yaa ", error);
        });
    }

    getTags(){
        const headers = {}
        axios
        .get(API_CASE_TAGS, {headers : headers})
        .then((res) => {
            const tags = res.data.tags;
            console.log(tags);
            this.setState({ tags });
        })
        .catch((error) => {
            console.log("Error yaa ", error);
        });
    }

    getMain(){
        const headers = {}
        axios
        .get(API_MAIN, {headers : headers})
        .then((res) => {
            const main = res.data.main;
            this.setState({ main });
        })
        .catch((error) => {
            console.log("Error yaa ", error);
        });
    }


    render() {
        const { cases, main} = this.state
        const param = {
            title: industries[window.lang],
            subtitle: "",
            breadcrumb: industries[window.lang]
        }
        return (
            <main>
                <div role="main" className="main">
                    <PageHeader param={param} />
                    <section>
                    <div className="container py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <p align="justify">                                    
                                </p>                    
                            </div>
                            <div className="col-lg-12" style={{border:'1px solid', padding: '30px 30px'}}>
                                {cases && cases.map((d) => (
                                <ul>
                                    <li>
                                        <a href={"/case/"+d.SLUG}><strong>{d.NAME}</strong></a><br/>
                                        <div dangerouslySetInnerHTML={ { __html: d.DESC_PROJECT } }></div>
                                    </li>
                                </ul>
                                ))}
                            </div>
                        </div>
                    </div>
                    </section>
                    <div className="container">
                        <div className="row pt-1 pb-4 mb-3">
                            <div className="col">
                                {/* <div className="sort-destination-loader sort-destination-loader-showing"> */}
                                    {/* <div className="row portfolio-list sort-destination" data-sort-id="portfolio"> */}
                                        {/* {cases && cases.map((d) => ( */}
                                            {/* <div className={"col-lg-4 isotope-item "+tag(d.TAG)} key={d.ID}>
                                                <a href={"/case/"+d.SLUG} className="text-decoration-none">
                                                    <span className="thumb-info custom-thumb-info-style-1 mb-4 pb-1 thumb-info-hide-wrapper-bg">
                                                    <span className="thumb-info-wrapper m-0">
                                                        <img src={d.FULL_PATH_IMAGE} className="img-fluid" alt="" />
                                                    </span>
                                                    <span className="thumb-info-caption bg-color-secondary p-3 pt-4 pb-4">
                                                        <span className="custom-thumb-info-title">
                                                        <span className="custom-thumb-info-name font-weight-semibold text-color-dark text-4">{d.NAME}</span> */}
                                                        {/* <span className="custom-thumb-info-desc font-weight-light">{d.PROJECTS_DESC}</span> */}
                                                        {/* </span>
                                                        <span className="custom-arrow" />
                                                    </span>
                                                    </span>
                                                </a>
                                            </div> */}
                                        {/* ))} */}
                                    {/* </div> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    <Contact data={main} />
                </div>
            </main>
        )
    }
}

export default Case_page
