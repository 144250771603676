import React, { Component } from "react";
import axios from "axios";
import { API_SET_LANGUAGE } from "../utils/constants";

class Language extends Component {
  constructor(props) {
    super(props);
    this.changeLang = this.changeLang.bind(this);
  }

  changeLang = (lang) => {
    const headers = {};
    var data = {
      lang: lang,
    };
    axios
      .post(API_SET_LANGUAGE, data, { headers: headers })
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  };

  render() {
    return (
      <li>
        <a
          onClick={() => this.changeLang("id")}
          value="in"
          style={{ cursor: "pointer" }}
        >
          ID
        </a>
        |
        <a
          onClick={() => this.changeLang("en")}
          value="en"
          style={{ cursor: "pointer" }}
        >
          EN
        </a>
      </li>
    );
  }
}

export default Language;
