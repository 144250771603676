import React, { Component } from 'react'
import { PageHeader, PageBody, Contact, Leader, Step}  from '../components';
import { API_MAIN, API_PERSON_LEADER,API_PERSON_EXPERT,API_STEP_LANDING } from "../utils/constants";
import axios from "axios";
import { about, core, desc_people, mision, people, vision, expert } from '../utils/string'

export class About_page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            misi: [],
            visi: [],
            value: [],
            expert: [],
            leader: [],
            main:[]
        };
      }

    componentDidMount(){
        const headers = {}
        axios
        .get(API_MAIN, {headers : headers})
        .then((res) => {
            const misi = res.data.main.MISI;
            const visi = res.data.main.VISI;
            const value = res.data.main.VALUE;
            const main = res.data.main;
            this.setState({ main });
            this.setState({ misi });
            this.setState({ visi });
            this.setState({ value });
        })
        .catch((error) => {
            console.log("Error yaa ", error);
        });

        this.getPersonExpert();
        this.getPersonLeader();
        this.getStep();
    }

    getPersonExpert(){
        const headers = {}
        axios
        .get(API_PERSON_EXPERT, {headers : headers})
        .then((res) => {
            const expert = res.data.person;
            this.setState({ expert });
        })
        .catch((error) => {
            console.log("Error yaa ", error);
        });
    }

    getStep(){
        const headers = {}
          axios
          .get(API_STEP_LANDING, {headers : headers})
          .then((res) => {
              const step = res.data.strategies;
              this.setState({ step });
          })
          .catch((error) => {
              console.log("Error yaa ", error);
          });
    }

    getPersonLeader(){
        const headers = {}
        axios
        .get(API_PERSON_LEADER, {headers : headers})
        .then((res) => {
            const leader = res.data.person;
            this.setState({ leader });
        })
        .catch((error) => {
            console.log("Error yaa ", error);
        });
    }

    render() {
        const { misi,visi,main,value,expert,leader,step} = this.state;
        const header = {
            title: about[window.lang],
            subtitle: '',
            breadcrumb: about[window.lang]
        }
        return (
            <main>
                <div role="main" className="main">
                    <PageHeader param={header} />
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h2>{vision[window.lang]}</h2>
                                </div>
                            </div>
                            <p align="jutify">
                                <PageBody param={visi} />
                            </p>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h2>{mision[window.lang]}</h2>
                                </div>
                            </div>
                            <p align="jutify">
                                <PageBody param={misi} />
                            </p>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h2>{core[window.lang]}</h2>
                                </div>
                            </div>
                            <p align="jutify">
                                <PageBody param={value} />
                            </p>
                        </div>
                    </section>

                    <section>
                        <div className="container">
                            <div className="col-lg-12 pl-0">
                                {/* <Step data={step} /> */}
                                <br></br>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h2>{people[window.lang]}</h2>
                                </div>
                            </div>
                            <div className="container py-5">
                                <div className="row">
                                    <div className="col-lg-12 pl-0">
                                        <p align="jutify">
                                            {desc_people[window.lang]}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Leader data={leader} />

                    <section className="section-secondary custom-section-padding">
                    <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h2>{expert[window.lang]}</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="row team-list sort-destination" data-sort-id="team">
                                        {expert &&
                                        expert.map((d) => (
                                            <div key={d.ID} className="col-md-3 isotope-item leadership">
                                                <div className="team-item mb-3 p-0 pb-2">
                                                    <a  href={"/person/"+d.SLUG} className="text-decoration-none">
                                                    <span className="image-wrapper">
                                                        <img src={d.FULL_PATH_IMAGE} width="100%" alt="" className="img-fluid" />
                                                    </span>
                                                    </a>
                                                    <div className="team-infos" style={{height:'150px'}}>
                                                    <a href={"/person/"+d.SLUG} className="text-decoration-none">
                                                        <span className="team-member-name text-color-dark font-weight-semibold text-4">{d.NAME}</span>
                                                        <span className="team-member-desc font-weight-light">{d.POSITION}</span>
                                                    </a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Contact data={main} />
                </div>
            </main>
        )
    }
}

export default About_page
