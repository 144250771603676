import React, { Component } from "react";
import { getYear, getDay, getMonth } from "../utils/util";
import { PageHeader, CareerPageBody, Contact } from "../components";
import axios from "axios";
import {
  API_GET_MONEV,
  API_SUBMIT_LAMARAN,
  API_UPDATE_MONEV,
  API_MAIN,
} from "../utils/constants";
import {
  Questionnaire,
  JobSetup,
  Client,
  ClientContact,
  submit_now,
  port_clause,
  port_clause_desc,
  agre_full,
  agre_not,
  agre_part,
  note_clause,
  port_clause_disclamer,
  industry_knowledge,
  service_feeback,
  question_1,
  question_2,
  business_delivery,
  question_3,
  question_4,
  lot_worse,
  worse,
  better,
  lot_better,
  question_5,
  question_6,
  question_7,
  question_8,
  question_9,
  question_10,
  service_satisfaction,
  ManagerAssist,
} from "../utils/string";

export class Kuisioner extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileInput = React.createRef();
    this.state = {
      no: "",
      slug: "",
      main: [],
      detail: [],
      service: [],
      client: [],
      clientContact: [],
      data: [],
      selectedFile: null,
      message: "",
    };
  }

  componentDidMount() {
    this.getMain();
    this.getData(this.props.match.params.no, this.props.match.params.slug);
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getData(no, slug) {
    const headers = {};
    axios
      .get(API_GET_MONEV + "/" + no + "/" + slug, { headers: headers })
      .then((res) => {
        const detail = JSON.parse(res.data.data.RAW_DATA);
        const service = detail.Service;
        const client = detail.Client;
        const clientContact = client.ClientContact;
        const data = res.data.data;

        this.setState({ detail });
        this.setState({ service });
        this.setState({ client });
        this.setState({ clientContact });
        this.setState({ data });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const element = document.getElementsByClassName(name);
    element[0].textContent =
      value.replace("0", "") == "" ? "0" : value.replace("0", "");
  };

  handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);

    const data = new FormData();
    data.append(
      "portfolio_clause_status",
      formData.get("portfolio_clause_status")
    );
    data.append("portfolio_clause_note", formData.get("portfolio_clause_note"));
    data.append(
      "industry_knowledge_point",
      formData.get("industry_knowledge_point")
    );
    data.append(
      "industry_knowledge_note",
      formData.get("industry_knowledge_note")
    );
    data.append(
      "business_delivery_point",
      formData.get("business_delivery_point")
    );
    data.append(
      "business_delivery_status",
      formData.get("business_delivery_status")
    );
    data.append(
      "business_delivery_note",
      formData.get("business_delivery_note")
    );
    data.append(
      "business_adapted_point",
      formData.get("business_adapted_point")
    );
    data.append("business_adapted_note", formData.get("business_adapted_note"));
    data.append("business_value_note", formData.get("business_value_note"));
    data.append(
      "service_satisfaction_point",
      formData.get("service_satisfaction_point")
    );
    data.append("service_recomen_point", formData.get("service_recomen_point"));
    data.append("form_layanan", formData.get("form_layanan"));
    data.append("no", formData.get("no"));
    data.append("client", formData.get("client"));
    // console.log(param);

    axios
      .post(API_UPDATE_MONEV, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.error) {
          const message = (
            <div className="contact-form-error alert alert-danger mt-4">
              <strong>Error!</strong> {res.data.data}
              <span className="mail-error-message text-1 d-block" />
            </div>
          );
          this.setState({ message });
        } else {
          const message = (
            <div className="contact-form-success alert alert-success mt-4">
              <strong>Success!</strong> {res.data.data}
            </div>
          );
          this.setState({ message });
          setInterval(function () {
            window.location.href = "/Thank";
          }, 2000);
        }
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { service, client, data, clientContact, detail, main, message } =
      this.state;
    const param = {
      title: Questionnaire[window.lang],
      subtitle: Questionnaire[window.lang],
      breadcrumb: Questionnaire[window.lang],
    };
    console.log(detail);
    return (
      <main>
        <div role="main" className="main">
          <PageHeader param={param} />
          <section className="custom-section-padding">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h1> {Questionnaire[window.lang]}</h1>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <br></br>
                </div>
              </div>
              <div className="row">
                <article className="blog-post col-lg-4 mb-1">
                  <h4>{JobSetup[window.lang]}</h4>
                  <p className="mb-0">
                    <span>{data.JOBSETUP_NO}</span>
                  </p>
                  <p className="mb-0">
                    <span>{data.SERVICE_NAME}</span>
                  </p>
                  <p className="mb-0">
                    <span>{detail.JobSetupFiscalYears}</span>
                  </p>
                  <p className="mb-0">
                    <span>
                      {getDay(detail.JobSetupPeriodeStart)}{" "}
                      {getMonth(detail.JobSetupPeriodeStart)}{" "}
                      {getYear(detail.JobSetupPeriodeStart)} 
                      &nbsp;-&nbsp;&nbsp;
                      {getDay(detail.JobSetupPeriodeFinish)}{" "}
                      {getMonth(detail.JobSetupPeriodeFinish)}{" "}
                      {getYear(detail.JobSetupPeriodeFinish)}
                    </span>
                  </p>
                </article>
                <article className="blog-post col-lg-4 mb-1">
                  <h4>{Client[window.lang]}</h4>
                  <p className="mb-0">
                      <span>
                        {this.props.match.params.slug.replace(/-/g, " ").toUpperCase()}
                      </span>
                  </p>
                  {/* <p className="mb-0">
                    <span>{client.ClientWebsite}</span>
                  </p> */}
                  {/* <p className="mb-0">
                    <span>{client.ClientNpwpNo}</span>
                  </p> */}
                  <p className="mb-0">
                    <span>{client.ClientDesc}</span>
                  </p>
                </article>
                {/* <article className="blog-post col-lg-3 mb-1">
                  <h4>{ClientContact[window.lang]}</h4>
                  <div>
                    <div className="mb-0">
                      <span>{client.ContactName}</span>
                    </div>
                    <div className="mb-0">
                      <span>
                        <i className="icon-envelope icons"></i>&nbsp;&nbsp;
                        {client.ContactEmail}
                      </span>
                    </div>
                    <div className="mb-0">
                      <span>
                        <i className="icon-phone icons"></i>&nbsp;&nbsp;
                        {client.ContactPhone}
                      </span>
                    </div>
                  </div>
                </article> */}
                <article className="blog-post col-lg-4 mb-1">
                  <h4>{ManagerAssist[window.lang]}</h4>
                  <div>
                    <div className="mb-0">
                      <span>{data.MANAGE_ASSIST}</span>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </section>
          <section className="flat-row page-careers">
            <div className="container">
              <div
                className="careers-open "
                style={{ backgroundColor: "#fff" }}
              >
                <div className="info-apply pb-3">
                  {/* <h1>{main.upload_cv}</h1> */}
                  <form
                    onSubmit={this.handleSubmit}
                    className="custom-contact-form-style-1 contactform style4  clearfix"
                    encType="multipart/form-data"
                  >
                    <div className="form-row">
                      <div className="form-group col">
                        <div className="custom-input-box">
                          {/* <i className="icon-user icons text-color-primary" /> */}
                          <input
                            type="hidden"
                            data-msg-required="Please enter your name."
                            value={detail.JobSetupNo}
                            className="form-control"
                            name="no"
                            placeholder={main.name}
                            required
                          />
                          <input
                            type="hidden"
                            data-msg-required="Please enter your name."
                            value="1"
                            className="form-control"
                            name="form_layanan"
                            required
                          />
                          <input
                            type="hidden"
                            data-msg-required="Please enter your name."
                            value={this.props.match.params.slug}
                            className="form-control"
                            name="client"
                            placeholder={this.props.match.params.slug}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <h4> {port_clause[window.lang]}</h4>
                        <p className="mb-0">
                          <span>{port_clause_desc[window.lang]}</span>
                        </p>
                      </div>
                    </div>
                    <div className="form-row">
                      <div
                        className="form-group col pt-3"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <div className="custom-input-box">
                          <input
                            type="radio"
                            name="portfolio_clause_status"
                            value="Agree Fully"
                            required
                          />
                          &nbsp;{agre_full[window.lang]}&nbsp;&nbsp;
                          <input
                            type="radio"
                            name="portfolio_clause_status"
                            value="Agree Partially"
                            required
                          />
                          &nbsp;{agre_part[window.lang]}&nbsp;&nbsp;
                          <input
                            type="radio"
                            name="portfolio_clause_status"
                            value="Do Not Agree"
                            required
                          />
                          &nbsp;{agre_not[window.lang]}
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col">
                        <div className="custom-input-box">
                          <i className="icon-bubble icons text-color-primary" />
                          <textarea
                            maxLength={5000}
                            rows={10}
                            className="form-control"
                            name="portfolio_clause_note"
                            placeholder={note_clause[window.lang]}
                            defaultValue={""}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <p className="mb-0">
                          <span>{port_clause_disclamer[window.lang]}</span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mt-5">
                        <h4
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          {service_feeback[window.lang]}
                        </h4>
                        <h4> {industry_knowledge[window.lang]}</h4>
                        <p className="mb-0">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: question_1[window.lang],
                            }}
                          ></span>
                          <div className="form-row mb-5  pb-4">
                            <div className="form-group col">
                              <div
                                className="custom-input-box"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <input
                                  type="range"
                                  min={0}
                                  max={50}
                                  // value={5}
                                  step={10}
                                  className="form-control"
                                  name="industry_knowledge_point"
                                  onChange={this.handleChange}
                                  required
                                />
                                <div className="indicator industry_knowledge_point">
                                  5
                                </div>
                              </div>
                            </div>
                          </div>
                          <span
                            className="mt-5"
                            dangerouslySetInnerHTML={{
                              __html: question_2[window.lang],
                            }}
                          ></span>
                          <div className="form-row mt-3 mb-4">
                            <div className="form-group col">
                              <div className="custom-input-box">
                                <i className="icon-bubble icons text-color-primary" />
                                <textarea
                                  maxLength={5000}
                                  rows={10}
                                  className="form-control"
                                  name="industry_knowledge_note"
                                  placeholder={note_clause[window.lang]}
                                  defaultValue={""}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mt-5">
                        <h4> {business_delivery[window.lang]}</h4>
                        <p className="mb-0">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: question_3[window.lang],
                            }}
                          ></span>
                          <div className="form-row mb-5  pb-4">
                            <div className="form-group col">
                              <div
                                className="custom-input-box"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <input
                                  type="range"
                                  min={0}
                                  max={50}
                                  // value={5}
                                  step={10}
                                  className="form-control"
                                  name="business_delivery_point"
                                  onChange={this.handleChange}
                                  required
                                />
                                <div className="indicator business_delivery_point">
                                  5
                                </div>
                              </div>
                            </div>
                          </div>
                          <span
                            className="mt-5"
                            dangerouslySetInnerHTML={{
                              __html: question_4[window.lang],
                            }}
                          ></span>
                          <div className="form-row">
                            <div
                              className="form-group col pt-3 mb-5"
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <div className="custom-input-box">
                                <input
                                  type="radio"
                                  name="business_delivery_status"
                                  value="A lot Worse"
                                  required
                                />
                                &nbsp;{lot_worse[window.lang]}&nbsp;&nbsp;
                                <input
                                  type="radio"
                                  name="business_delivery_status"
                                  value="Worse"
                                  required
                                />
                                &nbsp;{worse[window.lang]}&nbsp;&nbsp;
                                <input
                                  type="radio"
                                  name="business_delivery_status"
                                  value="Better"
                                  required
                                />
                                &nbsp;{better[window.lang]}&nbsp;&nbsp;
                                <input
                                  type="radio"
                                  name="business_delivery_status"
                                  value="A lot Better"
                                  required
                                />
                                &nbsp;{lot_better[window.lang]}
                              </div>
                            </div>
                          </div>
                          <span
                            className="mt-5"
                            dangerouslySetInnerHTML={{
                              __html: question_5[window.lang],
                            }}
                          ></span>
                          <div className="form-row mt-3 mb-4">
                            <div className="form-group col">
                              <div className="custom-input-box">
                                <i className="icon-bubble icons text-color-primary" />
                                <textarea
                                  maxLength={5000}
                                  rows={10}
                                  className="form-control"
                                  name="business_delivery_note"
                                  placeholder={note_clause[window.lang]}
                                  defaultValue={""}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <span
                            className="mt-5"
                            dangerouslySetInnerHTML={{
                              __html: question_6[window.lang],
                            }}
                          ></span>
                          <div className="form-row mb-5  pb-4">
                            <div className="form-group col">
                              <div
                                className="custom-input-box"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <input
                                  type="range"
                                  min={0}
                                  max={50}
                                  // value={5}
                                  step={10}
                                  className="form-control"
                                  name="business_adapted_point"
                                  onChange={this.handleChange}
                                  required
                                />
                                <div className="indicator business_adapted_point">
                                  5
                                </div>
                              </div>
                            </div>
                          </div>
                          <span
                            className="mt-5"
                            dangerouslySetInnerHTML={{
                              __html: question_7[window.lang],
                            }}
                          ></span>
                          <div className="form-row mt-3 mb-4">
                            <div className="form-group col">
                              <div className="custom-input-box">
                                <i className="icon-bubble icons text-color-primary" />
                                <textarea
                                  maxLength={5000}
                                  rows={10}
                                  className="form-control"
                                  name="business_adapted_note"
                                  placeholder={note_clause[window.lang]}
                                  defaultValue={""}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <span
                            className="mt-5"
                            dangerouslySetInnerHTML={{
                              __html: question_8[window.lang],
                            }}
                          ></span>
                          <div className="form-row mt-3 mb-4">
                            <div className="form-group col">
                              <div className="custom-input-box">
                                <i className="icon-bubble icons text-color-primary" />
                                <textarea
                                  maxLength={5000}
                                  rows={10}
                                  className="form-control"
                                  name="business_value_note"
                                  placeholder={note_clause[window.lang]}
                                  defaultValue={""}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mt-5">
                        <h4> {service_satisfaction[window.lang]}</h4>
                        <p className="mb-0">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: question_9[window.lang],
                            }}
                          ></span>
                          <div className="form-row mb-5  pb-4">
                            <div className="form-group col">
                              <div
                                className="custom-input-box"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <input
                                  type="range"
                                  min={0}
                                  max={50}
                                  // value={5}
                                  step={10}
                                  className="form-control"
                                  name="service_satisfaction_point"
                                  onChange={this.handleChange}
                                  required
                                />
                                <div className="indicator service_satisfaction_point">
                                  5
                                </div>
                              </div>
                            </div>
                          </div>
                          <span
                            className="mt-5"
                            dangerouslySetInnerHTML={{
                              __html: question_10[window.lang],
                            }}
                          ></span>
                          <div className="form-row mb-5  pb-4">
                            <div className="form-group col">
                              <div
                                className="custom-input-box"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <input
                                  type="range"
                                  min={0}
                                  max={50}
                                  // value={5}
                                  step={10}
                                  className="form-control"
                                  name="service_recomen_point"
                                  onChange={this.handleChange}
                                  required
                                />
                                <div className="indicator service_recomen_point">
                                  5
                                </div>
                              </div>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                    <div className="message">{message}</div>
                    <div className="row justify-content-between mt-5">
                      <div className="col-md-2">
                        <div className="form-group">
                          <input
                            type="submit"
                            name="submit"
                            value={submit_now[window.lang]}
                            className="btn btn-outline custom-border-width btn-primary custom-border-radius font-weight-semibold text-uppercase mb-4"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <div className="blank"></div>
        </div>
      </main>
    );
  }
}

export default Kuisioner;
