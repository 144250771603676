import React, { Component } from 'react'
import { PageHeader, PageBody, Contact }  from '../components';
import axios from "axios";
import { API_DETAIL_PAGE, API_MAIN } from '../utils/constants';

export class Detail_page extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            detail: [],
            slug: '',
            main:[]
        };
      }

    componentDidMount(){
        this.getData(this.props.match.params.slug);
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params.slug !== prevProps.match.params.slug) {
            this.getData(this.props.match.params.slug);
        }
     }

    getData(slug) {
        const headers = {}
        axios
        .get(API_DETAIL_PAGE+"?slug="+slug, {headers : headers})
        .then((res) => {
            const detail = res.data.pages;
            console.log(detail)
            this.setState({ detail });
        })
        .catch((error) => {
            console.log("Error yaa ", error);
        });
    }

    getMain(){
        const headers = {}
        axios
        .get(API_MAIN, {headers : headers})
        .then((res) => {
            const main = res.data.main;
            this.setState({ main });
        })
        .catch((error) => {
            console.log("Error yaa ", error);
        });
    }

    render() {
        const { detail, main } = this.state
        const param = {
            title: detail.TITLE,
            subtitle: detail.SUB_TITLE,
            breadcrumb: detail.TITLE
        }
        return (
            <main>
                <div role="main" className="main">
                    <PageHeader param={param} />
                    <PageBody param={detail.DESC_PAGES} />
                    <Contact data={main} />
                </div>
            </main>
        )
    }
}

export default Detail_page
