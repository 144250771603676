import React from "react"

const BlogPageBody = ({param}) => {
    return (
        <div class="container">
			<div class="row pt-1 pb-5 mb-3">
				<div class="col">
                    {param && param.map((d) => (
                    <div key={d.ID}>
                        <div className="row mt-2 mb-2">
                        <article className="blog-post col">
                            <div className="row">
                            <div className="col-sm-8 col-lg-5">
                                <div className="blog-post-image-wrapper">
                                    <a href={"/service/"+d.SLUG} title="Read More">
                                        <img src={d.FULL_PATH_IMAGE} alt="" className="img-fluid mb-4" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-7">
                                <h2> {d.TITLE }</h2>
                                <span>{d.SUB_TITLE }</span>
                                    {<div dangerouslySetInnerHTML={ { __html: d.DESC_SERVICE } }></div>}
                                {/* <hr className="solid" />                                 */}
                                {/* <a className="btn btn-outline custom-border-width btn-primary custom-border-radius font-weight-semibold text-uppercase" href={"/service/"+d.SLUG} title="Read More">Read More</a> */}
                            </div>
                            </div>
                        </article>
                        </div>
                        <hr className="solid tall mt-5" />
                    </div>
                    ))}
                </div>
            </div>
      </div>
    )
}

export default BlogPageBody;