import React from "react";
import bg_contact from "../assets/new_img/contact-background2.jpg";
import { API_MAIN, API_POST_MESSAGE } from "../utils/constants";
import axios from "axios";
// import {
//   contact,
//   desc_contact,
//   write,
//   call,
//   location,
//   mail,
//   sosmed,
//   submit_now,
// } from "../utils/string";
import ReCAPTCHA from "react-google-recaptcha";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      main: [],
      message: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  onChange(value) {
    console.log("Captcha value:", value);
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    if (data.get("g-recaptcha-response") != "") {
      var param = {
        email: data.get("email"),
        name: data.get("name"),
        message: data.get("message"),
      };

      const headers = {};
      axios
        .post(API_POST_MESSAGE, param, { headers: headers })
        .then((res) => {
          // var res = JSON.stringify({error:true,msg:"Thank you for the message you sent, we will immediately respond to the message."});
          // var data = JSON.parse(res)
          if (res.data.error) {
            const message = (
              <div className="contact-form-error alert alert-danger mt-4">
                <strong>Error!</strong> {res.data.msg}
                <span className="mail-error-message text-1 d-block" />
              </div>
            );
            this.setState({ message });
          } else {
            const message = (
              <div className="contact-form-success alert alert-success mt-4">
                <strong>Success!</strong> {res.data.msg}
              </div>
            );
            this.setState({ message });
          }
        })
        .catch((error) => {
          console.log("Error yaa ", error);
        });
    } else {
      const message = (
        <div className="contact-form-error alert alert-danger mt-4">
          <strong>Error!</strong> Please verify that you are a Human.
          <span className="mail-error-message text-1 d-block" />
        </div>
      );
      this.setState({ message });
    }
  }

  render() {
    const recaptchaRef = React.createRef();
    const { main, message } = this.state;
    return (
      <section
        className="section section-text-light section-background m-0"
        style={{ background: `url(${bg_contact})`, backgroundSize: "cover" }}
      >
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-6 custom-sm-margin-top">
              <h2 className="font-weight-bold">
                <font color="black">{main.write}</font>
              </h2>
              <form
                onSubmit={this.handleSubmit}
                className="custom-contact-form-style-1 form-errors-light"
              >
                <div className="message">{message}</div>
                <input
                  type="hidden"
                  name="subject"
                  defaultValue="Contact Message Received"
                />
                <div className="form-row">
                  <div className="form-group col">
                    <div className="custom-input-box">
                      <i className="icon-user icons text-color-primary" />
                      <input
                        type="text"
                        data-msg-required="Please enter your name."
                        maxLength={100}
                        className="form-control"
                        name="name"
                        placeholder="Name*"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <div className="custom-input-box">
                      <i className="icon-envelope icons text-color-primary" />
                      <input
                        type="email"
                        data-msg-required="Please enter your email address."
                        data-msg-email="Please enter a valid email address."
                        maxLength={100}
                        className="form-control"
                        name="email"
                        placeholder="Email*"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <div className="custom-input-box">
                      <i className="icon-bubble icons text-color-primary" />
                      <textarea
                        maxLength={5000}
                        data-msg-required="Please enter your message."
                        rows={10}
                        className="form-control"
                        name="message"
                        placeholder="Message*"
                        required
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6Lf6LB4eAAAAAGSXagUWhv0Rj6IuPwhamLTB1pOG"
                  // sitekey="6LcgCB4eAAAAAHNfU8JWGyLyTgwhDkjxrq-V8Zj_"
                  onChange={this.onChange}
                />
                <div className="form-row">
                  <div className="form-group col">
                    <input
                      type="submit"
                      value={main.submit_now}
                      className="c-button btn btn-primary btn-modern font-weight-bold text-2 py-3 btn-px-4 appear-animation"
                      data-loading-text="Loading..."
                    />
                  </div>
                </div>
              </form>
            </div>

            <div className="col-lg-6 mb-5 mb-lg-0">
              <h2 className="font-weight-bold">
                <font color="black">{main.contact}</font>
              </h2>
              <div className="custom-opacity-font">
                <font color="black">{main.desc_contact}</font>
              </div>

              <div className="row">
                <div className="col-md-5 custom-sm-margin-top">
                  <h4 className="mb-1">
                    <font color="black">{main.call}</font>
                  </h4>
                  <a
                    href={"tel:"+main.NO_TELP}
                    className="text-decoration-none"
                    target="_blank"
                    rel="noreferrer"
                    title="Call Us"
                  >
                    <span className="custom-call-to-action-2 text-color-light text-2 custom-opacity-font">
                      <span className="info text-4">
                        <font color="black">{main.NO_TELP}</font>
                      </span>
                    </span>
                  </a>
                  <a
                    href={"tel:"+main.NO_TELP2}
                    className="text-decoration-none"
                    target="_blank"
                    rel="noreferrer"
                    title="Call Us"
                  >
                    <span className="custom-call-to-action-2 text-color-light text-2 custom-opacity-font">
                      <span className="info text-4">
                        <font color="black">{main.NO_TELP2}</font>
                      </span>
                    </span>
                  </a>
                </div>
                <div className="col-md-7 custom-sm-margin-top">
                  <h4 className="mb-1">
                    <font color="black">{main.location}</font>
                  </h4>
                  <div className="custom-opacity-font">
                    <font color="black">
                      <div className="text-2">
                        <div
                          dangerouslySetInnerHTML={{ __html: main.ADDRESS }}
                        ></div>
                      </div>
                    </font>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-5 custom-sm-margin-top">
                  <h4 className="mb-1">
                    <font color="black">{main.mail}</font>
                  </h4>
                  <a
                    href="mail:mail@example.com"
                    className="text-decoration-none"
                    target="_blank"
                    rel="noreferrer"
                    title="Mail Us"
                  >
                    <span className="custom-call-to-action-2 text-color-light text-2 custom-opacity-font">
                      <span className="info text-3">
                        <font color="black">{main.EMAIL}</font>
                      </span>
                    </span>
                  </a>
                </div>
                <div className="col-md-7 custom-sm-margin-top">
                  <h4 className="mb-1">
                    <font color="black">Social Media</font>
                  </h4>
                  <ul className="social-icons social-icons-clean custom-social-icons-style-1 mt-2 custom-opacity-font">
                    <li className="social-icons-facebook">
                      <a
                        href={main.FB}
                        target="_blank"
                        rel="noreferrer"
                        title="Facebook"
                      >
                        <font color="black">
                          <i className="fab fa-facebook-f" />
                        </font>
                      </a>
                    </li>
                    <li className="social-icons-twitter">
                      <a
                        href={main.TW}
                        target="_blank"
                        rel="noreferrer"
                        title="Twitter"
                      >
                        <font color="black">
                          <i className="fab fa-twitter" />
                        </font>
                      </a>
                    </li>
                    <li className="social-icons-instagram">
                      <a
                        href={main.IG}
                        target="_blank"
                        rel="noreferrer"
                        title="Instagram"
                      >
                        <font color="black">
                          <i className="fab fa-instagram" />
                        </font>
                      </a>
                    </li>
                    <li className="social-icons-linkedin">
                      <a
                        href={main.LI}
                        target="_blank"
                        rel="noreferrer"
                        title="Linkedin"
                      >
                        <font color="black">
                          <i className="fab fa-linkedin-in" />
                        </font>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
