import React from "react"
import { getYear, getDay, getMonth } from "../utils/util"

const CareerPageBody = ({param}) => {
    return (
        <div className="container">
			<div className="row pt-1 pb-5 mb-3">
				<div className="col">
                    {param && param.map((d) => (
                    <div key={d.ID}>
                        <div className="row mt-2 mb-2">
                        <article className="blog-post col">
                            <div className="row">
                            <div className="col-sm-8 col-lg-5">
                                <div className="blog-post-image-wrapper">
                                <a href={"/career/"+d.SLUG} title="Read More">
                                    <img src={d.FULL_PATH_IMAGE} alt="" width="100%" className="img-fluid mb-4" />
                                </a>
                                {/* <span className="blog-post-date bg-color-primary text-color-light font-weight-bold">
                                    {getDay(d.DATE_CAREER)}
                                    <span className="month-year font-weight-light">
                                    {getMonth(d.DATE_CAREER)}-{getYear(d.DATE_CAREER)}
                                    </span>
                                </span> */}
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-7">
                                <h2> {d.POSITION }</h2>
                                <h2> {d.TITLE }</h2>
                                    <p>
                                        {/* <span dangerouslySetInnerHTML={ { __html: d.DESC_CAREER?.replace(/<[^>]+>/g, '') } }></span>... */}
                                        <div dangerouslySetInnerHTML={ { __html: d.DESC_CAREER } }></div>
                                    </p>
                                <hr className="solid" />
                                <div className="post-infos d-flex">                                    
                                    <span className="info comments ml-5">
                                        Deadline :
                                        <span className="comments-number text-color-primary font-weight-semibold">
                                        {getDay(d.DATE_CAREER)}/{getMonth(d.DATE_CAREER)}/{getYear(d.DATE_CAREER)}
                                        </span>
                                    </span>                                    
                                </div>
                                <a className="btn btn-outline custom-border-width btn-primary custom-border-radius font-weight-semibold text-uppercase mt-5" href={"/career/"+d.SLUG} title="Read More">READ MORE</a>
                            </div>
                            </div>
                        </article>
                        </div>
                        <hr className="solid tall mt-5" />
                    </div>
                    ))}
                </div>
            </div>
      </div>
    )
}

export default CareerPageBody;