import React from "react";
import { getYear, getDay, getMonth } from "../utils/util";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  FacebookIcon,
  WhatsappIcon,
  LineIcon,
  TelegramIcon,
  LinkedinIcon,
} from "react-share";

const NewsBody = ({ param }) => {
  const download = [];
  if (param.FEATURE_DOC) {
    // download.push(<a href={"http://localhost/pro-visioner/admin/docs/index/"+param.FEATURE_DOC} target="_blank">DOWNLOAD</a>)
    download.push(
      <Link
        to={"/admin/docs/index/" + param.FEATURE_DOC}
        className="btn btn-outline custom-border-width btn-primary custom-border-radius font-weight-semibold text-uppercase"
        target="_blank"
      >
        DOWNLOAD DOC
      </Link>
    );
  }
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-8">
          <p align="justify">
            <div dangerouslySetInnerHTML={{ __html: param.DESC_NEWS }}></div>
          </p>
        </div>
        <div className="col-lg-4">
          <img src={param.FULL_PATH_IMAGE} className="img-fluid mb-4" alt="" />
          <div className="post-infos d-flex">
            <span className="info posted-by">
              Posted by :
              <span className="post-author font-weight-semibold text-color-dark">
                Admin pro-visioner
              </span>
            </span>
          </div>
          <span className="month-year font-weight-light">
            Date : {getDay(param.DATE_NEWS)} {getMonth(param.DATE_NEWS)}{" "}
            {getYear(param.DATE_NEWS)}
          </span>
          <div className="post-infos d-flex">
            <span className="info posted-by">
              Tags :
              <span className="post-author font-weight-semibold text-color-dark">
                {param.TAG}
              </span>
            </span>
          </div>
          <div style={{ marginBottom: "10px" }}>
            Share: &nbsp;
            <TwitterShareButton url={window.location.href}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            &nbsp;
            <FacebookShareButton url={window.location.href}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            &nbsp;
            <LinkedinShareButton url={window.location.href}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
            &nbsp;
            <WhatsappShareButton url={window.location.href}>
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
            &nbsp;
            <LineShareButton url={window.location.href}>
              <LineIcon size={32} round={true} />
            </LineShareButton>
            &nbsp;
            <TelegramShareButton url={window.location.href}>
              <TelegramIcon size={32} round={true} />
            </TelegramShareButton>
          </div>
          {download}
        </div>
      </div>
    </div>
  );
};

export default NewsBody;
