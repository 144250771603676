const SearchBar = ({ searchQuery, setSearchQuery }) => (
    <form action="/tax/page/1" method="get">
        <div style={{position: 'absolute', top: '0', right: '0', marginRight: '30px', marginTop: '10px'}}>
            <input 
                value={searchQuery}
                onInput={e => setSearchQuery(e.target.value)}
                type="text" 
                placeholder="Search" 
                style={{border: '1px solid', textIndent: '10px'}}
                name="s"
            />
        </div>
    </form>
);

export default SearchBar