export const home = {
  en: "Home",
  id: "Beranda",
};
export const leaders = {
  en: "LEADERS",
  id: "PIMPINAN",
};
export const service = {
  en: "Services",
  id: "Layanan Pajak",
};
export const desc_service = {
  en: "Our primary service focuses on customer satisfaction with in-depth discussion in designing innovative solutions, whiles honoring relevant tax advice is what our company strives for. We are here to collaborate and provide assistance for all your financial and tax needs. Our service focuses on several technical things, including:",
  id: "Layanan utama kami berfokus pada kepuasan pelanggan dengan diskusi mendalam dalam merancang solusi inovatif, sedangkan menghormati nasihat pajak yang relevan adalah apa yang diperjuangkan oleh perusahaan kami. Kami di sini untuk bekerja sama dan memberikan bantuan untuk semua kebutuhan keuangan dan pajak Anda. Layanan kami berfokus pada beberapa hal teknis, antara lain:",
};
export const about_us = {
  en: "About Us",
  id: "Tentang Kami",
};
export const about = {
  en: "About",
  id: "Tentang Kami",
};
export const desc_about = {
  en: "Our motivation in delivering quality service is deeply rooted in our advisors. By combining a solid technical foundation and embracing Indonesia’s dynamic tax climate, we strive to help achieve your financial and taxation needs. Find out more from our profile below.",
  id: "Motivasi kami dalam memberikan layanan berkualitas berakar kuat pada para penasihat kami. Dengan menggabungkan dasar teknis yang kuat dan merangkul iklim perpajakan Indonesia yang dinamis, kami berusaha untuk membantu memenuhi kebutuhan keuangan dan perpajakan Anda. Cari tahu lebih lanjut dari profil kami di bawah ini.",
};
export const vision = {
  en: "Vision",
  id: "Visi",
};
export const mision = {
  en: "Mission",
  id: "Misi",
};
export const core = {
  en: "Core Value",
  id: "Nilai Perusahaan",
};
export const expert = {
  en: "Experts",
  id: "Pakar",
};
export const people_desc = {
  en: "Meet Our Professional",
  id: "Profesional Kami",
};
export const people = {
  en: "People",
  id: "Orang",
};
export const desc_people = {
  en: "We are a team, we work together in realizing the solution to your problem, we have the same vision standing here carrying out the same mission, doing the best because we are the best, we are very happy to help you, and we are very happy your business grows after this cooperation",
  id: "Kami adalah tim, kami bekerja sama dalam mewujudkan solusi untuk masalah Anda, kami memiliki visi yang sama berdiri di sini menjalankan misi yang sama, melakukan yang terbaik karena kami adalah yang terbaik, kami sangat senang membantu Anda, dan kami sangat senang bisnis Anda tumbuh setelah kerjasama ini",
};
export const industries = {
  en: "Industries",
  id: "Industri",
};
export const careers = {
  en: "Careers",
  id: "Karir",
};
export const sub_careers = {
  en: "Elevate your future and grow with us",
  id: "Tingkatkan karir dan masa depan Anda dan tumbuh bersama kami",
};
export const insight = {
  en: "Highlight Featured Insight",
  id: "Informasi Perpajakan Terbaru",
};
export const insightMenu = {
  en: "Tax Insight",
  id: "Informasi Perpajakan",
};
export const sub_insight = {
  en: "Our perspective and latest insights to expand tax, economic, and global issues related contents on the trends shaping the future of business",
  id: "Perspektif dan wawasan terbaru dari kami untuk memperluas pengetahuan dan menyebar informasi terkait masalah perpajakan, perekonomian, dan global tren yang membentuk masa depan bisnis",
};
export const contact = {
  en: "Contact Us",
  id: "Hubungi Kami",
};
export const sub_contact = {
  en: "We are here to connect with you",
  id: "Kami disini siap untuk membantu Anda",
};
export const desc_contact = {
  en: "For inquiries or requests that require a more personal response, we will make every attempt to respond within 24 hours. You may contact us by phone And/or visit our office.",
  id: "Untuk pertanyaan atau permintaan yang membutuhkan tanggapan yang lebih pribadi, kami akan berupaya untuk menanggapi dalam waktu 24 jam. Anda dapat menghubungi kami melalui telepon dan / atau mengunjungi kantor kami.",
};
export const write = {
  en: "Write Us",
  id: "Tulis pesan",
};
export const call = {
  en: "Call Us",
  id: "Hubungi kami",
};
export const call_now = {
  en: "Call Us Now",
  id: "Hubungi Kami",
};
export const location = {
  en: "Our Location",
  id: "Lokasi kami",
};
export const mail = {
  en: "Mail Us",
  id: "Email kami",
};
export const mail_now = {
  en: "Email Us Now",
  id: "Email Kami",
};
export const sosmed = {
  en: "Social Media",
  id: "Media Sosial",
};
export const read_more = {
  en: "Read More",
  id: "Lebih Lanjut",
};
export const submit_now = {
  en: "Submit Now",
  id: "Kirim",
};
export const send_a_message = {
  en: "Send a Message",
  id: "Kirim Pesan",
};
export const address = {
  en: "Address",
  id: "Alamat",
};
export const phone = {
  en: "Phone",
  id: "Telepon",
};
export const looking_for = {
  en: "Your Professional ",
  id: "Mitra Profesional ",
};
export const partner = {
  en: "Partner at tax",
  id: "Anda di Pajak",
};
export const categories = {
  en: "Categories",
  id: "Kategori",
};
export const Questionnaire = {
  en: "Questionnaire",
  id: "Kuisioner",
};
export const Client = {
  en: "Client",
  id: "Klien",
};
export const ClientContact = {
  en: "Client Contact",
  id: "Kontak Klien",
};
export const JobSetup = {
  en: "Project Detail",
  id: "Detail Proyek",
};
export const SaranKomentar = {
  en: "Suggestions & Comments",
  id: "Saran & Komentar",
};
export const ValueNilai = {
  en: "Value",
  id: "Nilai",
};

export const port_clause = {
  en: "Portfolio Clause",
  id: "Klausa Portofolio",
};

export const port_clause_desc = {
  en: "We hereby give Pro Visioner Konsultindo (PVK) permission to be included as part of PVK’s future portfolio (if needed), such as contact information provision to World Transfer Pricing and the likes, for the sole purpose of establishing PVK’s portfolio on previous engagements relating to similar projects or industries.",
  id: "Dengan ini kami memberikan izin kepada Pro Visioner Konsultindo (PVK) untuk dimasukkan sebagai bagian dari portofolio PVK (jika diperlukan), seperti penyediaan informasi kontak untuk World Transfer Pricing dan sejenisnya, dengan tujuan semata-mata untuk menggambarkan portofolio PVK atas servis perpajakan, khususnya atas jasa Transfer Pricing, yang pernah disampaikan sebelumnya terkait proyek atau seputar industri serupa.",
};

export const agre_full = {
  en: "Agree Fully",
  id: "Setuju Sepenuhnya",
};

export const agre_part = {
  en: "Agree Partially",
  id: "Setuju Sebagian",
};

export const agre_not = {
  en: "Do Not Agree",
  id: "Tidak Setuju",
};

export const note_clause = {
  en: "Note",
  id: "Note",
};

export const port_clause_disclamer = {
  en: "Disclaimer : Pro Visioner Konsultindo (PVK) reserves the right, with the Client’s permission, to display partial information relating to the Client’s previous engaged project(s) as part of a PVK’s portfolio. PVK understands that some information or aspect of the project may require they remain private in order to maintain the Confidentiality and Information Security Clause of the service agreement. Vital & confidential information for example, but not limited to; the Client’s name, business practices, financial or fiscal information and/or rulings, and other aspect requested shall be withheld and remain private from public view.",
  id: "Disclaimer : Pro Visioner Konsultindo (PVK) berhak, dengan izin Klien, untuk menampilkan sebagian informasi yang berkaitan dengan proyek klien sebelumnya yang terlibat sebagai bagian dari portofolio PVK. PVK memahami bahwa beberapa informasi atau aspek proyek mungkin mengharuskan mereka tetap pribadi untuk menjaga Kerahasiaan dan Klausul Keamanan Informasi dari perjanjian layanan. Informasi penting & rahasia misalnya, namun tidak terbatas pada; nama Klien, praktik bisnis, informasi keuangan atau fiskal dan/atau peraturan, dan aspek lain yang bersifat pribadi akan tetap dirahasiakan dari publik.",
};

export const service_feeback = {
  en: "SERVICE FEEDBACK",
  id: "UMPAN BALIK LAYANAN",
};

export const industry_knowledge = {
  en: "Industry Knowledge & Understanding",
  id: "Pengetahuan & Pemahaman Industri",
};

export const question_1 = {
  en: "<b>Question #1</b> : On this assignment how well did our industry expertise meet your expectations?",
  id: "<b>Pertanyaan #1</b> : Dalam penugasan ini, seberapa baik keahlian industri kami memenuhi harapan Anda?",
};

export const question_2 = {
  en: "<b>Question #2</b> : Based on your overall experience of PVK, what is the one area where we could most improve to further demonstrate industry expertise?",
  id: "<b>Pertanyaan #2</b> : Berdasarkan keseluruhan pengalaman Anda tentang PVK, area apa yang paling dapat kami tingkatkan untuk menunjukkan keahlian industri lebih lanjut?",
};

export const business_delivery = {
  en: "Business Delivery",
  id: "Pengiriman Bisnis",
};

export const question_3 = {
  en: "<b>Question #3</b> : How have we met your expectations with the quality of the tools and technology used in the service delivery?",
  id: "<b>Pertanyaan #3</b> : Bagaimana kami memenuhi harapan Anda dengan kualitas alat dan teknologi yang digunakan selama periode pelayanan?",
};

export const question_4 = {
  en: "<b>Question #4</b> : How was PVK’s service level compared to other providers you may have worked with?",
  id: "<b>Pertanyaan #4</b> : Bagaimana tingkat layanan PVK dibandingkan dengan penyedia lain yang mungkin pernah bekerja sama dengan Anda?",
};

export const lot_worse = {
  en: "A lot Worse",
  id: "Lebih Buruk",
};

export const worse = {
  en: "Worse",
  id: "Buruk",
};

export const lot_better = {
  en: "A lot Better",
  id: "Lebih Baik",
};

export const better = {
  en: "Better",
  id: "Baik",
};

export const question_5 = {
  en: "<b>Question #5</b> : Is there anything that distinguishes us or that we could learn from?",
  id: "<b>Pertanyaan #5</b> : Apakah ada yang membedakan kami atau yang bisa kami pelajari?",
};

export const question_6 = {
  en: "<b>Question #6</b> : How well have PVK adapted during the new working environment as a result of the Coronavirus (Covid-19) Pandemic?",
  id: "<b>Pertanyaan #6</b> : Seberapa baik PVK beradaptasi selama lingkungan kerja baru sebagai akibat dari Pandemi Coronavirus (Covid-19)?",
};

export const question_7 = {
  en: "<b>Question #7</b> : Are there any additional comments or remarks you wish to share?",
  id: "<b>Pertanyaan #7</b> : Apakah ada komentar atau komentar tambahan yang ingin Anda berikan?",
};

export const question_8 = {
  en: "<b>Question #8</b> : Thinking about what value means to you, please tell us the reasons for your score. What would you like us to have done better or differently on this engagement?",
  id: "<b>Pertanyaan #8</b> : Memikirkan apa arti nilai bagi Anda, beri tahu kami alasan skor Anda. Apa yang Anda ingin kami lakukan dengan lebih baik atau berbeda dalam pertunangan ini?",
};

export const question_9 = {
  en: "<b>Question #9</b> : How satisfied are you with the overall service experience during your service engagement?",
  id: "<b>Pertanyaan #9</b> : Seberapa puaskah Anda dengan keseluruhan pengalaman layanan selama keterlibatan layanan Anda?",
};

export const question_10 = {
  en: "<b>Question #10</b> : How likely are you to recommend PVK to your peers or colleagues?",
  id: "<b>Pertanyaan #10</b> : Seberapa besar kemungkinan Anda untuk merekomendasikan PVK kepada rekan atau kolega Anda?",
};

export const service_satisfaction = {
  en: "Service Satisfaction",
  id: "Kepuasan Layanan",
};

export const thank = {
  en: "Thank you for filling out the survey",
  id: "Terima kasih telah mengisi survei",
};

export const ManagerAssist = {
  en: "Partner / Manager Who Assist You",
  id: "Partner / Manajer Yang Membantu Anda",
};

export const tp_question_1 = {
  en: "<b>Question #1</b> : Kindly choose the answer most suitable to your experience during your service of engagement period ",
  id: "<b>Pertanyaan #1</b> : Silakan pilih jawaban yang paling sesuai untuk pertanyaan di bawah ini",
};

export const tp_question_1a = {
  en: "How would you rate the quality of our team’s tax knowledge provided to you?",
  id: "Bagaimana menurut Anda kualitas wawasan perpajakan tim kami pada proyek ini?",
};

export const tp_question_1b = {
  en: "How would you rate our team’s response quality in regarding issues that arise during the engagement?",
  id: "Bagaimana tim kami merespon isu-isu perpajakan yang muncul dalam proyek ini?",
};

export const tp_question_1c = {
  en: "How was the communication quality of our team with Tax Officers in the event of assisting /accompanying you to the Tax Office?",
  id: "Bagaimana menurut Anda kualitas bantuan tim kami dalam berkomunikasi/mendampingi Anda ke dan di Kantor Pajak?",
};

export const tp_question_1d = {
  en: "How would you describe our team’s professionalism in helping you in dealing with taxation matters?",
  id: "Bagaimana menurut Anda profesionalitas tim kami dalam membantu kebutuhan perpajakan Anda?",
};

export const tp_question_1e = {
  en: "How well have we fulfilled our monthly tax reporting in due time?",
  id: "Seberapa baik kita memenuhi bulanan kita pelaporan pajak tepat waktu?",
};

export const tp_question_1f = {
  en: "How would you describe our assistance during the preparation and reporting of the Corporate Income Tax Return (CITR) and/or Individual Income Tax Report (IITR), if any?",
  id: "Bagaimana Anda menilai kualitas bantuan kami selama proses penyusunan dan pelaporan Surat Pemberitahuan Tahunan Pajak Penghasilan Orang Pribadi dan/atau Badan (SPT Tahunan OP dan/atau PPh Badan), jika ada?",
};

export const tp_question_1g = {
  en: "How would you describe your overall level of satisfaction with entire experience during this engagement?",
  id: "Bagaimana Anda menilai tingkat kepuasan Anda secara keseluruhan terhadap proses kerjasama ini?",
};

export const tp_question_1h = {
  en: "<b>Question #2</b> : How likely are you to recommend us to your peers and/or colleagues?",
  id: "<b>Pertanyaan #2</b> : Seberapa besar kemungkinan Anda merekomendasikan kami kepada rekan dan/atau rekan kerja Anda?",
};

export const very_likely = {
  en: "Very likely",
  id: "Sangat Mungkin",
};

export const somewhat_likely = {
  en: "Somewhat likely",
  id: "Agak Mungkin",
};

export const neither_likely_nor_unlikely = {
  en: "Neither likely nor unlikely",
  id: "Tidak mungkin atau tidak mungkin",
};

export const somewhat_unlikely = {
  en: "Somewhat unlikely",
  id: "Agak tidak mungkin",
};

export const very_unlikely = {
  en: "Very unlikely",
  id: "Sangat tidak mungkin",
};

export const poor = {
  en: "Poor",
  id: "Buruk",
};

export const fair = {
  en: "Fair",
  id: "Cukup",
};

export const good = {
  en: "Good",
  id: "Baik",
};

export const excellent = {
  en: "Excellent",
  id: "Sangat Baik",
};

export const exceptional = {
  en: "Exceptional",
  id: "Luar Biasa",
};
