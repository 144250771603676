import { Link } from "react-router-dom";

export const getYear = (date) => {
  if(date=='0000-00-00'){
    return "";
  }
  var new_date = new Date(date);
  return new_date.getFullYear();
};
export const getDay = (date) => {
  var new_date = new Date(date);
  if(date=='0000-00-00'){
    return "";
  }
};
export const getMonth = (date) => {
  if(date=='0000-00-00'){
    return "";
  }
  var new_date = new Date(date);
  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return month[new_date.getMonth()];
};
export const tag = (tags) => {
  return tags.replaceAll(",", "");
};
export const cleanAndSubstring = (data, substring) => {
  return data.replace(/<[^>]+>/g, "").substring(0, substring);
};
export const taxPagination = (total, page, perpage, query, category_query) => {
  const list = [];
  for (var i = 0; i < total / perpage; i++) {
    var url = "/tax/page/" + parseInt(i + 1);
    if (query) {
      url = url + "?s=" + query;
    }
    if (category_query) {
      url = url + "?cat=" + category_query;
    }
    list.push(
      <Link
        key={parseInt(i)}
        to={url}
        className={parseInt(page) === parseInt(i) + 1 ? "active" : ""}
      >
        {parseInt(i + 1)}
      </Link>
    );
  }
  var first = "/tax/page/1";
  var last = "/tax/page/" + parseInt(i);
  if (query) {
    first = first + "?s=" + query;
    last = last + "?s=" + query;
  }
  if (category_query) {
    first = first + "?cat=" + category_query;
    last = last + "?cat=" + category_query;
  }
  return (
    <div className="pagination">
      <Link to={first}>&laquo;</Link>
      {list}
      <Link to={last}>&raquo;</Link>
    </div>
  );
};
