import React, { Component } from "react";
import { PageHeader2, Contact, NewsBody } from "../components";
import { getYear, getDay, getMonth } from "../utils/util";
import axios from "axios";
import {
  API_DETAIL_NEWS,
  API_DETAIL_EVENTS,
  API_MAIN,
  API_NEWS_CATEGORY,
  API_POST_KEHADIRAN,
  API_NEWS_LANDING,
  API_DETAIL_EVENTS_BYID,
} from "../utils/constants";
import bg_image from "../assets/img/background-invitation.jpg";

export class Detail_news extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      detail: [],
      detail2: [],
      peserta: [],
      slug: "",
      category: [],
      main: [],
      showHideUndangan: false,
      undangan: [],
      kolega: [],
      materi: [],
      message: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideComponent = this.hideComponent.bind(this);
  }

  componentDidMount() {
    this.getMain();
  }

  componentDidUpdate(prevProps) {
    // if (this.props.match.params.slug !== prevProps.match.params.slug) {
    //   this.getData(this.props.match.params.slug);
    // }
  }

  getData(slug, idpeserta) {
    const headers = {};
    axios
      .get(API_DETAIL_EVENTS_BYID + "?id=" + slug + "&idpeserta=" + idpeserta, {
        headers: headers,
      })
      .then((res) => {
        const detail = res.data.events;
        const peserta = res.data.peserta_events;
        this.setState({ detail });
        this.setState({ peserta });
        if (this.state.main.LANG) {
          if (detail.LANG != this.state.main.LANG) {
            if (detail.LANG_REF == 0) {
              window.location.href = "/home";
              // console.log("0");
            } else {
              axios
                .get(
                  API_DETAIL_EVENTS_BYID +
                    "?id=" +
                    detail.LANG_REF +
                    "&idpeserta=" +
                    idpeserta,
                  {
                    headers: headers,
                  }
                )
                .then((res) => {
                  const detail2 = res.data.events;
                  // const peserta = res.data.peserta_events;
                  // console.log(peserta);
                  this.setState({ detail2 });
                  // this.setState({ peserta });
                  window.location.href =
                    "/events/" +
                    detail2.ID +
                    "/" +
                    idpeserta +
                    "/" +
                    this.props.match.params.kolega;
                });
              // console.log(detail.LANG_REF);
            }
          } else {
            console.log("LANG sama");
          }
        } else {
          console.log("LANG UNDIFINED");
        }
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }
  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
        this.getData(
          this.props.match.params.slug,
          this.props.match.params.idpeserta
        );
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  handleSubmit(event) {
    event.preventDefault();
    // console.log(event);
    const data = new FormData(event.target);
    // console.log(data);

    var param = {
      slug: this.props.match.params.slug,
      idpeserta: this.props.match.params.idpeserta,
      status: data.get("hadir"),
      name_c: data.get("name"),
      company_c: data.get("company"),
      email_c: data.get("email"),
      materi: data.get("materi"),
    };

    const headers = {};
    axios
      .post(API_POST_KEHADIRAN, param, { headers: headers })
      .then((res) => {
        console.log(res);
        // var res = JSON.stringify({error:true,msg:"Thank you for the message you sent, we will immediately respond to the message."});
        if (res.error) {
          const message = (
            <div className="contact-form-error alert alert-danger ">
              <strong>Error!</strong> {res.data.msg}
              <span className="mail-error-message text-1 d-block" />
            </div>
          );
          this.setState({ message });
        } else {
          const message = (
            <div className="contact-form-primary alert alert-primary">
              <strong>Success!</strong> {res.data.msg}
            </div>
          );
          this.setState({ message });
          setInterval(function () {
            window.location.href = "/";
          }, 3000);
        }
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const main = this.state.main;
    const detail = this.state.detail;
    const peserta = this.state.peserta;
    if (value == 2) {
      const kolega = [];
      const materi = [];
      if (this.props.match.params.kolega == 1) {
        kolega.push(
          <div>
            <p className="pb-2 pt-2">
              <div className="contact-form-error alert-success mb-2">
                {main.one_colega}
                {/* <span className="mail-error-message text-1 d-block" /> */}
              </div>
            </p>
            {/* <div className="message">{message}</div> */}
            <div className="form-row">
              <div className="form-group col">
                <div className="custom-input-box">
                  {/* <i className="icon-user icons text-color-primary" /> */}
                  <input
                    type="text"
                    data-msg-required="Please enter your name."
                    maxLength={100}
                    className="form-control"
                    name="name"
                    placeholder={main.name}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <div className="custom-input-box">
                  {/* <i className="icon-envelope icons text-color-primary" /> */}
                  <input
                    type="text"
                    data-msg-required="Please enter your email address."
                    data-msg-email="Please enter a valid email address."
                    maxLength={100}
                    className="form-control"
                    name="company"
                    placeholder={main.company}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <div className="custom-input-box">
                  {/* <i className="icon-envelope icons text-color-primary" /> */}
                  <input
                    type="email"
                    data-msg-required="Please enter your email address."
                    data-msg-email="Please enter a valid email address."
                    maxLength={100}
                    className="form-control"
                    name="email"
                    placeholder={main.email}
                  />
                </div>
              </div>
            </div>
            <div className="contact-form-error alert-danger mb-4">
              {main.not_colega}
              {/* <span className="mail-error-message text-1 d-block" /> */}
            </div>
          </div>
        );
      }
      this.setState({ kolega });
      this.hideComponent(false, detail, peserta, main, kolega, materi);
    } else {
      const kolega = [];
      const materi = [];
      materi.push(
        <div>
          <p className="pb-2 pt-2">
            <div className="contact-form-error alert-success mb-2">
              {main.for_not_attend}
              {/* <span className="mail-error-message text-1 d-block" /> */}
            </div>
            {main.accept_materi}
          </p>
          <input
            type="radio"
            name="materi"
            value="1"
            // checked={this.state.materi === 1}
            // onChange={this.onMateriChanged}
          />
          &nbsp;{main.yes}&nbsp;&nbsp;
          <input
            type="radio"
            name="materi"
            value="0"
            // checked={this.state.site === result.SITE_NAME}
            // onChange={this.onSiteChanged}
          />
          &nbsp;{main.no}
        </div>
      );
      this.setState({ materi });
      this.hideComponent(false, detail, peserta, main, kolega, materi);
    }
  };

  hideComponent(kondisi, detail, peserta, main, kolega, materi) {
    const headers = {};

    if (kondisi) {
      const undangan = [];
      const showHideUndangan = false;
      this.setState({ undangan });
      this.setState({ showHideUndangan });
    } else {
      const undangan = [];
      undangan.push(
        <div>
          <div className="content-item pr-5 pl-5 pt-3">
            <h2 className="title-item pt-2">{detail.NAME}</h2>

            <div
              dangerouslySetInnerHTML={{
                __html: detail.DESC_EVENT,
              }}
            ></div>
          </div>
          <hr className="solid tall" />
          <div className="pr-5 pl-5 pt-3 pb-2">
            <div>
              <h3 className="title-item pt-2">{main.rsvp}</h3>
            </div>
            <div>
              <p>{main.name}</p>

              <h4>{peserta.NAMA}</h4>
            </div>
            <div>
              <p>{main.company}</p>

              <h4>{peserta.COMPANY}</h4>
            </div>
            <div>
              <p>{main.email}</p>

              <h4>{peserta.EMAIL}</h4>
            </div>
          </div>
          <hr className="solid tall" />
          <div className="pr-5 pl-5 pb-5">
            <form
              onSubmit={this.handleSubmit}
              className="contactform style4  clearfix pr-5 pl-2"
            >
              <h3 className="pb-2">{main.attend}</h3>
              <input
                type="radio"
                name="hadir"
                value="2"
                // checked={this.state.site === result.SITE_NAME}
                onChange={this.handleChange}
              />
              &nbsp;{main.will_attend}
              <p></p>
              {kolega}
              <input
                type="radio"
                name="hadir"
                value="1"
                // checked={this.state.site === result.SITE_NAME}
                onChange={this.handleChange}
              />
              &nbsp;{main.not_attend}
              <p></p>
              {materi}
              <div class="col-md-3 pt-5">
                <div class="form-group">
                  <span className="flat-input">
                    <button
                      name="submit"
                      type="submit"
                      className="c-button btn btn-primary btn-modern font-weight-bold text-2 py-3 btn-px-4 appear-animation"
                      id="submit"
                      title="Submit now"
                    >
                      {main.send_invite}
                    </button>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      );
      const showHideUndangan = true;
      this.setState({ undangan });
      this.setState({ showHideUndangan });
    }
  }

  render() {
    const {
      detail,
      main,
      peserta,
      undangan,
      kolega,
      materi,
      showHideUndangan,
      message,
    } = this.state;
    const param = {
      title: detail.TITLE,
      subtitle: detail.SUB_TITLE,
      breadcrumb1: detail.TITLE,
      breadcrumb: "Feature Insight",
      breadcrumbLink: "/tax",
    };
    return (
      <main>
        <div role="main" className="main">
          <PageHeader2 param={param} />
          <section className="flat-row services-details">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="item-wrap">
                    <div
                      className="item item-details"
                      style={{
                        background: `url(${bg_image})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <div className="mb-5">
                        <div
                          className="content-item pr-5 pl-5 pt-3"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <h5 className="">{main.undagan}</h5>
                          {/* <br> */}
                          <h5 className=" pt-4 pb-4">
                            PT Pro Visioner Konsultindo
                          </h5>
                          <h5 className="">{main.invite}</h5>
                          <p>
                            <strong className=" pt-4">{peserta.NAMA}</strong>
                          </p>
                          <p>
                            <strong>{peserta.COMPANY}</strong>
                          </p>
                          <p className=" pb-4">
                            <strong>{main.in_place}</strong>
                          </p>

                          <button
                            className="btn btn-outline custom-border-width btn-primary custom-border-radius font-weight-semibold text-uppercase mb-4"
                            onClick={() =>
                              this.hideComponent(
                                showHideUndangan,
                                detail,
                                peserta,
                                main
                              )
                            }
                          >
                            {main.open_invite}
                          </button>
                        </div>
                        {undangan}
                        <div className="message">{message}</div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <RecentPost />
          <div className="col-lg-9 pt-3">
            <h2>{main.other_news}</h2>
            <hr className="solid tall" />
            {news &&
              news.map((d) => (
                <div className="entry-border clearfix pl-5">
                  <p>
                    {getDay(d.DATE_NEWS)} {getMonth(d.DATE_NEWS)}{" "}
                    {getYear(d.DATE_NEWS)} | Admin
                  </p>
                  <Link to={"/tax/" + d.SLUG}>
                    <h6>{d.TITLE}</h6>
                  </Link>
                </div>
              ))}
          </div> */}
              </div>
            </div>
          </section>
          <Contact data={main} />
        </div>
      </main>
    );
  }
}

export default Detail_news;
