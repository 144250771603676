import React, { Component } from 'react'
import { PageHeader, PageBody, Contact,Step }  from '../components';
import { API_MAIN,API_STEP_LANDING } from "../utils/constants";
import { about_us, core } from '../utils/string'
import axios from "axios";

export class Misi extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: [],
            main: []
        };
      }

    componentDidMount(){
        const headers = {}
        axios
        .get(API_MAIN, {headers : headers})
        .then((res) => {
            const content = res.data.main.VALUE;
            const main = res.data.main;
            this.setState({ main });
            this.setState({ content });
        })
        .catch((error) => {
            console.log("Error yaa ", error);
        });
        this.getStep();
    }

    getStep(){
        const headers = {}
          axios
          .get(API_STEP_LANDING, {headers : headers})
          .then((res) => {
              const step = res.data.strategies;
              this.setState({ step });
          })
          .catch((error) => {
              console.log("Error yaa ", error);
          });
    }

    render() {
        const { content,main,step } = this.state;
        const header = {
            title: about_us[window.lang],
            subtitle: core[window.lang],
            breadcrumb: core[window.lang]
        }
        return (
            <main>
                <div role="main" className="main">
                    <PageHeader param={header} />
                    <PageBody param={content} />
                    <section>
                        <div className="container">
                            <div className="col-lg-12">
                                {/* <Step data={step} /> */}
                                <br></br>
                            </div>
                        </div>
                    </section>
                    <Contact data={main} />
                </div>
            </main>
        )
    }
}

export default Misi
