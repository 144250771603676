import React from "react";
import { desc_service, read_more, service } from "../utils/string";

const Service = ({ data, main }) => {
  let className;
  if (data.length == 3) {
    className = "services";
  } else {
    className = "servicess";
  }

  return (
    <section className="section-third custom-section-padding">
      <div className="container">
        <div className="row">
          <div className="col">
            <h1>{main.SERVICES}</h1>
            <p align="justify">{main.desc_service}</p>
          </div>
        </div>
        <div
          className="row"
          style={{
            marginTop: "30px",
            marginBottom: "30px",
            justifyContent: "center",
          }}
        >
          {data &&
            data.map((d, i) => (
              <div className="col-lg-4 pl-0 pr-0" key={d.ID}>
                <a
                  href={"/service/" + d.SLUG}
                  className="text-decoration-none appear-animation"
                  data-appear-animation="fadeInUp"
                  data-appear-animation-delay="0"
                >
                  <div
                    className={`${className}-${i} feature-box custom-feature-box feature-box-style-2 text-center`}
                  >
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <img src={d.FULL_PATH_IMAGE} width="100%" alt="" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h2>{d.TITLE}</h2>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </a>
              </div>
            ))}
        </div>
        <div className="row">
          <div className="col-lg-12 text-left">
            {/* <Link to="/service" className="btn btn-outline custom-border-width btn-primary custom-border-radius font-weight-semibold text-uppercase mb-3"></Link> */}
            <a
              href="/service"
              className="btn btn-primary btn-modern font-weight-bold text-2 py-3 btn-px-4 appear-animation c-button"
              data-appear-animation="fadeInUpShorter"
              data-appear-animation-delay="1500"
            >
              {main.read_more}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;
