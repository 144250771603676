import React, { Component } from "react";
import { PageHeader, PageBody, Contact } from "../components";
import { API_MAIN } from "../utils/constants";
import { Questionnaire, thank } from "../utils/string";
import axios from "axios";

export class Thank extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: [],
      main: [],
    };
  }

  componentDidMount() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const content = res.data.main.VISI;
        const main = res.data.main;
        this.setState({ main });
        this.setState({ content });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { content, main } = this.state;
    const header = {
      title: Questionnaire[window.lang],
      subtitle: Questionnaire[window.lang],
      breadcrumb: Questionnaire[window.lang],
    };
    return (
      <main>
        <div role="main" className="main">
          <PageHeader param={header} />
          <div className="container py-2">
            <div className="row">
              <div className="col-lg-12 pl-0">
                <h1
                  style={{ textAlign: "center", margin: "100px auto" }}
                  align="justify"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: thank[window.lang],
                    }}
                  ></div>
                </h1>
              </div>
            </div>
          </div>
          <Contact data={main} />
        </div>
      </main>
    );
  }
}

export default Thank;
